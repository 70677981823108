import { createContext, useContext } from 'react'
import { ItemInBasket } from 'types/orderFormat'

interface ItemContextType {
  item: ItemInBasket
  updateItem: () => void
}

export const ItemContext = createContext<ItemContextType | undefined>(undefined)

export const useItemContext = () => {
  const context = useContext(ItemContext)
  if (!context) {
    throw new Error('items must be used within a ItemContext')
  }
  return context
}