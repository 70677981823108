import React, { createContext, ReactNode, useContext, useState } from "react";
import moment from "moment-timezone";
import { GetSettingsResponse } from "types/getSettingsResponse";

interface SettingsContextType {
  data: GetSettingsResponse | null;
  setData: (data: GetSettingsResponse) => void;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  isError: boolean;
  setError: (error: boolean) => void;
  lastFetchTime: moment.Moment | null;
  setLastFetchTime: (time: moment.Moment) => void;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
    children,
  }) => {
  const [data, setData] = useState<GetSettingsResponse | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState<moment.Moment | null>(null);

  return (
    <SettingsContext.Provider value={{ data, setData, isLoading, setLoading, isError, setError, lastFetchTime, setLastFetchTime }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
