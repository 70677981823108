import { ReactElement, useState } from "react"
import { MenuListPanelProps } from "../MenuListPanel/MenuListPanel"
import { Typography } from "components/Typography/Typography"
import classes from './Tabs.module.scss'

interface TabsProps { 
    children: ReactElement<MenuListPanelProps>[]
}

export const Tabs = ({ children }: TabsProps) => {
    const [selected, setSelected] = useState<number>(0)
    
    return (
        <div className={classes.Tabs}>
            <ul className={classes.TabsList} data-testid='menu-tabs'>
                {children.map((panel, index) => {
                    const isSelected = selected === index
                    return (
                        <li 
                            key={index}
                            onClick={() => setSelected(index)}
                            className={isSelected ? classes.Selected : ''}
                        >
                            <Typography format="h2" bold>{panel.props.title.toUpperCase()}</Typography>
                        </li>
                    )
                })}
            </ul>
            <div className="tab" data-testid='menu-selected-tab-content'>
                {children[selected]}
            </div>
        </div>
    )
}