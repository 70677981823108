import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const useGetIP = () => {
  const getIP = async () => {
    try {
        const response = await axios.get("https://api.ipify.org?format=json");
        return response.data.ip;
      } catch (error) {
        console.error(error);
        toast.error('Niestety nie udało nam się przetworzyć zamówienia. Proszę spróbować później.')
        return null;
      }
    }
    return { getIP }
};
