import { commonAddOns } from "./commonData"

export interface RadioOptions {
    title: string
    options: Map<string, number>
}

interface ItemInfo {
    fullDesc: string
    imageUrl: string
    addOns?: Map<string, number>
    radios?: RadioOptions[]
}

export interface MenuItem {
    id: number
    title: string
    desc: string
    price: number,
    itemInfo: ItemInfo 
}

export interface TabElement {
    title: string
    items: MenuItem[]
}

export const allItems: TabElement[] = [
    {
        title: 'Ziemniaki',
        items: [
            {
                id: 1,
                title: 'Ziemniak - Własna kompozycja',
                desc: 'Zbuduj własnego ziemniaka',
                price: 10.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z serem i masłem. Wybierz własny sos i dodatki',
                    imageUrl: 'sternik',
                    radios: [
                        {
                            title: "Sos",
                            options: new Map<string, number>([
                                ["Bez sosu", 0.00],
                                ["Pomidorowy", 7.00],
                                ["Curry", 7.00],
                                ["Fasolka w sosie pomidorowym", 7.00],
                                ["Fasolka BBQ", 7.00],
                                ["Fasolka Chilli", 7.00]
                            ])
                        },
                        {
                            title: "Dodatki",
                            options: new Map<string, number>([
                                ["Bez dodatków", 0.00],
                                ["Szarpana wieprzowina", 8.00],
                                ["Szarpany kurczak", 8.00],
                                ["Tuńczyk", 8.00],
                                ["Jackfruit BBQ", 8.00]
                            ])
                        }, 
                        {
                            title: "Extra",
                            options: commonAddOns
                        }, 
                        {
                            title: "Powiększ",
                            options: new Map<string, number>([
                                ["Standard", 0.00],
                                ["XL (powiększona baza)", 5.00],
                                ["XXL (powiększona baza i dodatki)", 12.00],
                            ])
                        }
                    ]
                }
            },
            {
                id: 2,
                title: 'Kapitana',
                desc: 'Fasolka w sosie pomidorowym, szarpany kurczak',
                price: 25.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z serem, masłem, fasolką w sosie pomidorowym i szarpanym kurczakiem.',
                    imageUrl: 'kapitan',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }, 
                    {
                        title: "Powiększ",
                        options: new Map<string, number>([
                            ["Standard", 0.00],
                            ["XL (powiększona baza)", 5.00],
                            ["XXL (powiększona baza i dodatki)", 12.00]
                        ])
                    }
                ]
                }
            },
            {
                id: 3,
                title: 'Pirata',
                desc: 'Sos curry, szarpana wieprzowina',
                price: 25.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z serem, masłem, sosem curry i szarpaną wieprzowiną.',
                    imageUrl: 'pirat',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }, 
                    {
                        title: "Powiększ",
                        options: new Map<string, number>([
                            ["Standard", 0.00],
                            ["XL (powiększona baza)", 5.00],
                            ["XXL (powiększona baza i dodatki)", 12.00]
                        ])
                    }
                ]
                }
            },
            {
                id: 4,
                title: 'Bossmana',
                desc: 'Fasolka chilli, szarpany kurczak',
                price: 25.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z serem, masłem, fasolką chilli i szarpanym kurczakiem.',
                    imageUrl: 'bossman',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }, 
                    {
                        title: "Powiększ",
                        options: new Map<string, number>([
                            ["Standard", 0.00],
                            ["XL (powiększona baza)", 5.00],
                            ["XXL (powiększona baza i dodatki)", 12.00]
                        ])
                    }
                ]
                }
            },
            {
                id: 5,
                title: 'Marynarza',
                desc: 'Tuńczyk, zielona cebulka',
                price: 20.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z serem, masłem, tuńczykiem i zieloną cebulką.',
                    imageUrl: 'marynarz',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }, 
                    {
                        title: "Powiększ",
                        options: new Map<string, number>([
                            ["Standard", 0.00],
                            ["XL (powiększona baza)", 5.00],
                            ["XXL (powiększona baza i dodatki)", 12.00]
                        ])
                    }
                ]
                }
            },
            {
                id: 6,
                title: 'Sternika',
                desc: 'Sos pomidorowy, szarpana wieprzowina',
                price: 25.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z serem, masłem, sosem pomidorowym i szarpaną wieprzowiną.',
                    imageUrl: 'sternik',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }, 
                    {
                        title: "Powiększ",
                        options: new Map<string, number>([
                            ["Standard", 0.00],
                            ["XL (powiększona baza)", 5.00],
                            ["XXL (powiększona baza i dodatki)", 12.00]
                        ])
                    }
                ]
                }
            },
            {
                id: 7,
                title: 'Korsarza (wegańska opcja)',
                desc: 'Sos pomidorowy, Jackfruit BBQ (bez sera)',
                price: 25.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z wegńskim masłem, sosem pomidorowym i Jackfruit BBQ. (Bez sera)',
                    imageUrl: 'korsarz',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }, 
                    {
                        title: "Powiększ",
                        options: new Map<string, number>([
                            ["Standard", 0.00],
                            ["XL (powiększona baza)", 5.00],
                            ["XXL (powiększona baza i dodatki)", 12.00]
                        ])
                    }
                ]
                }
            },
            {
                id: 8,
                title: 'Żeglarza',
                desc: 'Fasolka BBQ',
                price: 17.00,
                itemInfo: {
                    fullDesc: 'Ziemniak z serem, masłem i fasolką BBQ.',
                    imageUrl: 'zeglarz',
                    radios: [
                        {
                            title: "Extra",
                            options: commonAddOns
                        }, 
                        {
                        title: "Powiększ",
                        options: new Map<string, number>([
                            ["Standard", 0.00],
                            ["XL (powiększona baza)", 5.00]
                        ])
                    }
                ]
                }
            },
        ]
    },
    {
        title: 'Nachosy',
        items: [
            {
                id: 1,
                title: 'Nachosy - Własna kompozycja',
                desc: 'Zbuduj własne nachosy',
                price: 10.00,
                itemInfo: {
                    fullDesc: 'Nachosy z serem. Wybierz własny sos i dodatki',
                    imageUrl: 'nachos-base',
                    radios: [
                        {
                            title: "Sos",
                            options: new Map<string, number>([
                                ["Bez sosu", 0.00],
                                ["Pomidorowy", 7.00],
                                ["Curry", 7.00],
                                ["Fasolka w sosie pomidorowym", 7.00],
                                ["Fasolka BBQ", 7.00],
                                ["Fasolka Chilli", 7.00]
                            ])
                        },
                        {
                            title: "Dodatki",
                            options: new Map<string, number>([
                                ["Bez dodatków", 0.00],
                                ["Szarpana wieprzowina", 8.00],
                                ["Szarpany kurczak", 8.00],
                                ["Tuńczyk", 8.00],
                                ["Jackfruit BBQ", 8.00]
                            ])
                        }, 
                        {
                            title: "Extra",
                            options: commonAddOns
                        }
                    ]
                }
            },
            {
                id: 2,
                title: 'Barbarossa',
                desc: 'Fasolka chilli, szarpany kurczak',
                price: 25.00,
                itemInfo: {
                    fullDesc: 'Nachosy z serem, faolką chilli i szarpanym kurczakiem.',
                    imageUrl: 'nachos-meat',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }
                ]
                }
            },
            {
                id: 3,
                title: 'Wege Barbarossa (wegańska opcja)',
                desc: 'Fasolka BBQ, Jackfruit BBQ (bez sera)',
                price: 25.00,
                itemInfo: {
                    fullDesc: 'Nachosy z faolką BBQ i Jackfruit BBQ. (Bez sera)',
                    imageUrl: 'nachos-vegan',
                    radios: [ {
                        title: "Extra",
                        options: commonAddOns
                    }
                ]
                }
            }
        ]
    },
    {
        title: 'Desery',
        items: [
            {
                id: 1,
                title: 'Czarna perła',
                desc: 'Domowo robiona rolada czekoladowa',
                price: 7.00,
                itemInfo: {
                    fullDesc: 'Czekoladowa rolada z orzechami i herbatnikami.',
                    imageUrl: 'black-pearl',
                    radios: [ {
                        title: "Waga",
                        options: new Map<string, number>([
                            ["1 porcja", 0.00],
                            ["100g", 8.00]
                        ])
                    }
                ]
                }
            }
        ]
    },
    {
        title: 'Napoje',
        items: [
            {
                id: 1,
                title: 'Coca Cola',
                desc: '',
                price: 7.00,
                itemInfo: {
                    fullDesc: '',
                    imageUrl: 'cola'
                }
            },
            {
                id: 2,
                title: 'Sprite',
                desc: '',
                price: 7.00,
                itemInfo: {
                    fullDesc: '',
                    imageUrl: 'sprite'
                }
            },
            {
                id: 3,
                title: 'Fanta',
                desc: '',
                price: 7.00,
                itemInfo: {
                    fullDesc: '',
                    imageUrl: ''
                }
            },
            {
                id: 4,
                title: 'Lemoniada',
                desc: '',
                price: 10.00,
                itemInfo: {
                    fullDesc: '',
                    imageUrl: 'lemonade',
                    radios: [ {
                        title: "Rozmiar",
                        options: new Map<string, number>([
                            ["300ml", 0.00],
                            ["500ml", 3.00]
                        ])
                    }
                ]
                }
            }
        ]
    }
    
]

export const getDataByCategory = (category: string) => {
    return allItems.find(item => item.title === category)
}