import { Link } from 'components/Link/Link';
import classes from './Logo.module.scss'
import { useHandleScroll } from 'hooks/useHandleScroll';

export const Logo = () => {
    const { isScrolling } = useHandleScroll()
  
    return (
      <header className={`${classes.header} ${isScrolling ? classes.tiny : ''}`} data-testid='topBar-logo'>
        <Link anchor='home'>
          <div className={classes.logo}></div>
        </Link>
    </header>
    )
}