import { Typography } from "components/Typography/Typography";
import { useMemo } from "react";
import { DeliveryType } from "../../types";
import classes from "./DeliveryTypeSelector.module.scss";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";

interface DeliveryTypeSelectorProps {
  selected: DeliveryType;
  setSelected: (selected: DeliveryType) => void;
  isDeliveryAvaliable: boolean;
}

export const DeliveryTypeSelector = ({
  selected,
  setSelected,
  isDeliveryAvaliable,
}: DeliveryTypeSelectorProps) => {
  const isPickUpSelected = useMemo(() => {
    return selected === DeliveryType.PICK_UP;
  }, [selected]);

  return (
    <div
      className={classes.DeliveryTypeSelector}
      data-testid="delivery-type-selector"
    >
      <div
        onClick={() => setSelected(DeliveryType.PICK_UP)}
        className={classNames(
            isPickUpSelected && classes.DeliveryTypeSelector__ActiveLeft,
            !isDeliveryAvaliable && classes.DeliveryTypeSelector__ActiveSingle
        )}
        key={DeliveryType.PICK_UP}
        data-testid="pickUp-option"
      >
        <Typography
          format="h3"
          paragraphClassname={!isPickUpSelected ? classes.Paragraph : ""}
        >
          ODBIÓR
        </Typography>
      </div>
      {isDeliveryAvaliable && (
        <div
          onClick={() => setSelected(DeliveryType.DELIVERY)}
          className={
            !isPickUpSelected ? classes.DeliveryTypeSelector__ActiveRight : ""
          }
          key={DeliveryType.DELIVERY}
          data-testid="delivery-option"
        >
          <Typography
            format="h3"
            paragraphClassname={isPickUpSelected ? classes.Paragraph : ""}
          >
            DOSTAWA
          </Typography>
        </div>
      )}
    </div>
  );
};
