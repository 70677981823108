import { APIProvider } from "@vis.gl/react-google-maps";
import { GM_API_KEY } from "config";
import AutocompleteInput from "./AutocompleteInput";

const API_KEY = GM_API_KEY ?? "";

const AutocompleteAddress = () => {
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  
  return (
    <div onClick={handleDropdownClick}>
      <APIProvider
        apiKey={API_KEY}
        solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
      >
        <AutocompleteInput/>
      </APIProvider>
    </div>
  );
};

export default AutocompleteAddress;
