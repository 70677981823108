import { Typography } from "components/Typography/Typography";
import classes from "./AddOns.module.scss";
import { Checkbox } from "components/Checkbox/Checkbox";
import { useState } from "react";
import { useFormikContext } from "formik";
import { ItemInBasket } from "types/orderFormat";

interface AddOnsProps {
  items: Map<string, number>;
}

export const AddOns = ({ items }: AddOnsProps) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [costs, setCosts] = useState(0);
  const { setFieldValue } = useFormikContext<ItemInBasket>();

  const onCheck = (optionName: string, price: number) => {
    const updatedArr = [...checkedItems, optionName];
    setCheckedItems(updatedArr);
    setCosts(costs + price);
    setFieldValue("addOns", updatedArr);
    setFieldValue("cost.addOns", costs + price);
  };

  const onUncheck = (optionName: string, price: number) => {
    const updatedArr = checkedItems.filter((item) => item !== optionName);
    setCheckedItems(updatedArr);
    setCosts(costs - price);
    setFieldValue("addOns", updatedArr);
    setFieldValue("cost.addOns", costs - price);
  };

  return (
    <div className={classes.AddOns} data-testid="addOns-list">
      <Typography format="h2" bold>
        Dodaj
      </Typography>
      {Array.from(items.entries()).map(([name, price], index) => {
        const key = `${name.toLowerCase()}--addOn`
        const testID =  `${index}--addOn`
        return (
          <div className={classes.AddOns__Check} key={key} data-testid={testID}>
            <div className={classes.AddOns__CheckBox}>
              <Checkbox
                onChecked={() => onCheck(name, price)}
                onUnchecked={() => onUncheck(name, price)}
                testID="addOns-checkbox"
              />
              <Typography format="h3">{name}</Typography>
            </div>
            <Typography>+ {price.toFixed(2)} zł</Typography>
          </div>
        );
      })}
    </div>
  );
};
