import { Typography } from "components/Typography/Typography"
import classes from './MenuButton.module.scss'
import classNames from "classnames"
import { Link } from "components/Link/Link";


interface MenuButtonProps {
    classname?: string; 
    testId?: string
  }

export const MenuButton = ({ classname, testId }: MenuButtonProps) => {
    return (
         <Link anchor='menu'>
        <div className={classNames(classes.MenuButton, classname)} data-testid={testId}>
            <Typography format="h3" bold>
                MENU
            </Typography>
        </div>
        </Link>
    )
}