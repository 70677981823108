export enum DeliveryType {
    PICK_UP = 'odbiór',
    DELIVERY = 'dostawa'
}

export interface SharedOrderTypes {
    type: DeliveryType
    addCutlery: boolean
    comment?: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    hasAcceptedTCs: boolean
}

export interface DeliveryTypeForm extends SharedOrderTypes {
    address: string
    placeObject: google.maps.places.PlaceResult | null
}

export type OrderFormValues = SharedOrderTypes | DeliveryTypeForm

export const pickupInitialValues: SharedOrderTypes = {
    type: DeliveryType.PICK_UP,
    addCutlery: false,
    comment: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    hasAcceptedTCs: false
};

export const deliveryInitialValues: DeliveryTypeForm = {
    type: DeliveryType.DELIVERY,
    addCutlery: false,
    comment: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    placeObject: null,
    hasAcceptedTCs: false
};