export const checkAddreesLocation = (delValues: google.maps.places.PlaceResult | null) => {
    if (!delValues || !delValues.geometry) return

    const userLat = delValues.geometry.location?.lat(); 
    const userLng = delValues.geometry.location?.lng(); 

    if(!userLat || !userLng) return 

    return calculateDistance(userLat, userLng);
}

export const calculateDistance = (lat2: number, lng2: number) => {
    const lat1 = 49.974358
    const lng1 = 20.056353
    // 49.97444, 20.05644
    const toRadians = (deg: number) => (deg * Math.PI) / 180;
  
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLng = toRadians(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c) <= 6// Distance in kilometers
  };
  