import { ReactNode, useEffect, useRef } from 'react'
import classes from './BaseModal.module.scss'
import classNames from 'classnames'

interface BaseModalProps {
    children: ReactNode
    testID?: string
    classname?: string
    onClose: () => void
}

export const BaseModal = ({ onClose, testID, classname, children }: BaseModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null);

    // TODO: Enable it once dropdown is fixed
    
    // useEffect(() => {
    //   function handleClickOutside(event: MouseEvent) {
    //     if (modalRef.current && !modalRef.current.contains(event.target as Node) && !document.querySelector('.pac-container')?.contains(event.target as Node)) {
    //       onClose();
    //     }
    //   }
    //   document.addEventListener('mousedown', handleClickOutside);
  
    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // }, [onClose]);

    return (
        <div className={classNames(classes.BaseModal, classname)} data-testid={testID}>
            <div className={classes.BaseModal__Modal}>
                {children}
            </div>
        </div>
    )
}