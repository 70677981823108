import { Radio } from "components/Radio/Radio";
import classes from "components/Modals/AddItemModal/Radios/Radios.module.scss";
import { Typography } from "components/Typography/Typography";
import { useState } from "react";

interface RadioListProps {
  items: Map<string, number>;
  setCheckedOption: (itemName: string, price: number) => void;
  testID?: string;
}

export const RadioList = ({
  items,
  setCheckedOption,
  testID,
}: RadioListProps) => {
  const [checkedId, setChecked] = useState<string | undefined>(
    Array.from(items.keys())[0]
  );

  const setCheckedRadio = (itemId: string, price: number) => {
    setChecked(itemId);
    setCheckedOption(itemId, price);
  };

  return (
    <div className={classes.Radios__RadioList} data-testid={testID}>
      {Array.from(items.entries()).map(([name, price], index) => {
        const key = `${name}--radio`;
        const radioTestID = `${index}--radio`;
        return (
          <div
            className={classes.Radios__Radio}
            key={key}
            data-testid={radioTestID}
          >
            <div className={classes.Radios__RadioButton}>
              <Radio
                itemId={name}
                checkedId={checkedId}
                radioText={name}
                setCheckedRadio={(value) => setCheckedRadio(value, price)}
              />
            </div>
            {price > 0 && (
              <Typography classname={classes.Radios__Paragraph}>
                + {price.toFixed(2)} zł
              </Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};
