import { useState, useEffect } from "react";
import OpenMap from "./OpenMap";

export const Map = () => {
  const [hasConsent, setHasConsent] = useState(false);

  useEffect(() => {
    // Listen for Cookiebot's consent event
    window.addEventListener("CookieConsentDeclaration", (event: any) => {
      setHasConsent(event.detail && event.detail.consent.given);
    });

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("CookieConsentDeclaration", (event: any) => {
        setHasConsent(event.detail && event.detail.consent.given);
      });
    };
  }, []);

  return (
    <>
    {hasConsent ? (
      <iframe
      className="cookieconsent-optin-preferences"
      title="captain-potato-map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2565.971302867271!2d20.05386547710889!3d49.97439672152578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716417eee48b7a3%3A0x86e9922408cc40c0!2sRo%C5%BCnowska%207%2C%2032-020%20Wieliczka%2C%20Poland!5e0!3m2!1sen!2suk!4v1724537491863!5m2!1sen!2suk"
      width="100%"
      height="500"
      style={{ border: 0 }}
      allowFullScreen={true}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      sandbox="allow-same-origin allow-scripts allow-popups"
      data-testid="contact-map"
    ></iframe>
    ) : (
        <OpenMap/>
    )}
    </>
  );
};
