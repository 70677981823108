import { MenuListPanel } from "./MenuListPanel/MenuListPanel";
import { Tabs } from "./Tabs/Tabs";
import { allItems } from "data/menuListData";
import { MenuList } from "./MenuList/MenuList";
import useWindowDimensions from "hooks/useWindowDimensions";
import { MobileTabs } from "./Mobile/MobileTabs/MobileTabs";

export const MenuTabs = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      {width < 830 ? (
        <MobileTabs>
          {allItems.map((tab) => {
            return (
              <MenuListPanel title={tab.title} key={tab.title}>
                <MenuList title={tab.title} items={tab.items} />
              </MenuListPanel>
            )
          })}
        </MobileTabs>
      ) : (
        <Tabs>
          {allItems.map((tab) => {
            return (
              <MenuListPanel title={tab.title} key={tab.title}>
                <MenuList title={tab.title} items={tab.items} />
              </MenuListPanel>
            )
          })}
        </Tabs>
      )}
    </>
  );
};
