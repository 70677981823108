import axios from "axios";
import { useCallback } from "react";
import { ENV, SETTINGS_API } from "config";
import { useSettings } from "context/SettingsTimeProvider";
import { GetSettingsResponse } from "types/getSettingsResponse";
const moment = require("moment-timezone");

export const useGetSettings = () => {
  const {
    data,
    setData,
    isLoading,
    setLoading,
    setError,
    lastFetchTime,
    setLastFetchTime,
  } = useSettings();

  const fetchSettings = useCallback(async () => {
    if(ENV !== 'production') {
      return {
        delivery: false,
        collection: true
      }
    }
    if (isLoading) {
      return;
    }
    const now = moment();

    if (lastFetchTime && now.diff(lastFetchTime, "minutes") < 3) {
      return data;
    }

    setError(false);
    setLoading(true);
    try {
      const response = await axios.get<GetSettingsResponse>(
        `${SETTINGS_API}/getSettings`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data);
      return response.data;
    } catch {
      setError(true);
      return null;
    } finally {
      setLoading(false);
      setLastFetchTime(moment());
    }
  }, [lastFetchTime]);

  return { fetchSettings };
};
