import { IoMdRadioButtonOn, IoMdRadioButtonOff } from "react-icons/io";
import classes from "./Radio.module.scss";
import { Typography } from "components/Typography/Typography";

interface RadioProps {
  itemId: string;
  radioText: string;
  setCheckedRadio: (checked: string) => void;
  checkedId?: string;
}

export const Radio = ({
  itemId,
  checkedId,
  radioText,
  setCheckedRadio,
}: RadioProps) => {

    const uncheckRadio = () => {
        if(itemId === checkedId) return
        setCheckedRadio("")
    }
  return (
    <div id={itemId} className={classes.Radio} data-testid="radio-button">
      {itemId === checkedId ? (
        <div className={classes.Radio__RadioButton} onClick={uncheckRadio} >
          <IoMdRadioButtonOn style={{ minWidth: '22px' }}/>
          <Typography format="h3">{radioText}</Typography>
        </div>
      ) : (
        <div className={classes.Radio__RadioButton} onClick={() => setCheckedRadio(itemId)} >
          <IoMdRadioButtonOff style={{ minWidth: '22px' }}/>
          <Typography format="h3">{radioText}</Typography>
        </div>
      )}
    </div>
  );
};
