import { Point } from "./types";

export const terms: Point[] = [
    {
        title: "Postanowienia ogólne",
        points: [
            {
                description: "Niniejszy Regulamin stanowi regulamin świadczenia usług drogą elektroniczną, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2002 r. Nr 144, poz. 1204 z późn. zm.)."
            },
            {
                description: "Regulamin skierowany jest do wszystkich Użytkowników i Klientów korzystających z Platformy."
            },
            {
                description: "Użytkownik, Klient oraz Restaurator są zobowiązani do przestrzegania postanowień niniejszego Regulaminu."
            },
            {
                description: "Wszystkie informacje udostępnione na Platformie, w tym dotyczące towarów i usług oraz ich cen, nie stanowią oferty w rozumieniu art. 66 Kodeksu Cywilnego, lecz zaproszenie do zawarcia umowy zgodnie z art. 71 Kodeksu Cywilnego."
            },
            {
                description: "Zakazane jest korzystanie z Platformy w sposób sprzeczny z prawem, dobrymi obyczajami lub naruszający uzasadnione interesy Restauratora. Użytkownicy i Klienci nie mogą podejmować działań, które mogłyby wyrządzić szkodę materialną lub wizerunkową Restauratorowi."
            },
            {
                description: "Użytkownicy i Klienci nie mają prawa umieszczać na Platformie treści bezprawnych."
            },
            {
                description: "Z chwilą złożenia Zamówienia, Użytkownik staje się Klientem."
            },
            {
                description: "Klient oświadcza, że podane przez niego dane w formularzu zamówienia, w tym dane adresowe i adres e-mail, są zgodne z prawdą, kompletne i prawidłowe. W przypadku podania fałszywych danych lub danych należących do osób trzecich, Restaurator zastrzega sobie prawo do anulowania Zamówienia i zawiadomienia odpowiednich organów."
            },
            {
                description: "Restaurator nie ponosi odpowiedzialności za transakcje dokonane przy użyciu danych Klienta, jeżeli Klient nie poinformował Restauratora niezwłocznie o bezprawnym wykorzystaniu swoich danych."
            },
            {
                description: "Wyrażenie zgody na przestrzeganie niniejszego Regulaminu przy korzystaniu z Platformy oznacza pełną akceptację jego warunków, bez potrzeby zawierania odrębnej umowy."
            },
        ]
    },
    {
        title: "Wymagania techniczne",
        points: [
            {
                description: "Do korzystania z Platformy Użytkownik musi dysponować urządzeniem końcowym (komputer, tablet, telefon) z dostępem do Internetu."
            },
            {
                description: "Prawidłowe funkcjonowanie Platformy wymaga użycia aktualnej wersji jednej z następujących przeglądarek internetowych: Google Chrome, Safari, Microsoft Edge, Mozilla Firefox, Opera."
            },
            {
                description: "Szczegółowe informacje dotyczące polityki prywatności, w tym wykorzystywania plików cookies, są dostępne w Polityce Prywatności na stronie (strona restauracji, zakładka Polityka prywatności)."
            },
            {
                description: "Aby zapewnić prawidłowe korzystanie z Platformy i oferowanych na niej usług, Użytkownik powinien posiadać urządzenie z dostępem do publicznej sieci Internet oraz kompatybilną przeglądarkę internetową."
            },
            {
                description: "Użytkownik przyjmuje do wiadomości, że w przypadku słabego połączenia z Internetem lub jego braku, funkcjonalność Platformy może być ograniczona lub całkowicie niedostępna."
            }
        ]
    },
    {
        title: "Przyjmowanie i realizacja zamówień",
        points: [
            {
                description: "Warunkiem korzystania z usług Platformy jest zapoznanie się z niniejszym Regulaminem i jego akceptacja."
            },
            {
                description: "Składając Zamówienie, Klient zobowiązuje się do podania prawdziwych i kompletnych danych wymaganych przez Platformę oraz akceptuje fakt, że Zamówienie wiąże się z obowiązkiem zapłaty."
            },
            {
                description: "Restaurator zastrzega sobie prawo do potwierdzenia złożonego Zamówienia oraz weryfikacji danych Klienta za pomocą telefonu lub poczty elektronicznej. W przypadku braku kontaktu z Klientem, niekompletnych danych, podejrzenia, że dane są nieprawdziwe, należą do osób trzecich, bądź że promocja nie przysługuje Klientowi, Restaurator ma prawo anulować Zamówienie, podając przyczynę."
            },
            {
                description: "Jeżeli Klient skorzystał z promocji, rabatów lub innych preferencyjnych warunków, do których nie miał prawa, zobowiązany jest do uiszczenia pełnej ceny za zamówione produkty. Restaurator ma prawo weryfikować uprawnienia Klienta do korzystania z preferencyjnych warunków w trakcie realizacji Zamówienia."
            },
            {
                description: "Restaurator określa obszar dostawy Zamówień. Jeśli adres dostawy podany przez Klienta znajduje się poza tym obszarem, Klient zostanie poinformowany o niemożności realizacji Zamówienia przez Sprzedawcę."
            }
        ]
    },
    {
        title: "Informacje dodatkowe dotyczące zamówień internetowych",
        points: [
            {
                description: "Umowę między Klientem a Restauratorem uważa się za zawartą dopiero w momencie, gdy Restaurator potwierdzi przyjęcie Zamówienia do realizacji, wysyłając wiadomość e-mail z potwierdzeniem na adres podany w formularzu Zamówienia."
            },
            {
                description: "Wszystkie ceny podawane przez Restauratora wyrażone są w polskich złotych (PLN) i są cenami brutto, obejmującymi podatek VAT."
            },
            {
                description: "Restaurator ma prawo anulować potwierdzone zamówienie w następujących przypadkach:",
                subs: [
                    "naruszenia zasad opisanych w trzecim punkcie Regulaminu;", 
                    "wystąpienia awarii technicznych lub innych ograniczeń, które uniemożliwiają lub znacznie utrudniają realizację zamówienia;",
                    "braku odpowiednich składników lub produktów niezbędnych do realizacji zamówienia, których Restaurator nie mógł przewidzieć mimo dochowania należytej staranności;",
                    "uzasadnionych wątpliwości co do prawdziwości podanych przez Klienta danych, ich przynależności do osób trzecich, bądź braku pełnej zdolności do czynności prawnych Klienta."
                ]
            },
            {
                description: "Restaurator potwierdza przyjęcie zamówienia do realizacji poprzez wyświetlenie odpowiedniej informacji na ekranie urządzenia, na którym Klient dokonał zamówienia, oraz wysłanie wiadomości e-mail, a w szczególnych przypadkach również wiadomości SMS. Wykonanie którejkolwiek z powyższych czynności oznacza skuteczne potwierdzenie przyjęcia zamówienia do realizacji."
            },
            {
                description: "Podany czas realizacji zamówienia jest orientacyjny, a jego przekroczenie o maksymalnie 80% nie stanowi podstawy do reklamacji ani nie jest traktowane jako opóźnienie w realizacji zamówienia."
            },
            {
                description: "Wszelkie zdjęcia potraw dostępne na Platformie mają charakter poglądowy, a ich rzeczywisty wygląd może odbiegać od przedstawionego na fotografiach."
            }
        ]
    },
    {
        title: "Formy płatności",
        points: [
            {
                description: "Sprzedawca umożliwia następujące metody płatności:",
                subs: [
                    "Płatności elektroniczne i kartą płatniczą – realizowane za pośrednictwem systemu płatności elektronicznych PayU S.A., ul. Grunwaldzka 182, 60-166 Poznań, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Poznaniu – Nowe Miasto i Wilda w Poznaniu, Wydział VIII Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000274399, o kapitale zakładowym w wysokości 4.944.000 PLN, w całości opłaconym, NIP: 779-23-08-495, REGON 300523444. Aktualne metody płatności są dostępne na stronie internetowej PayU: https://www.payu.pl."
                ]
            },
            {
                description: "Składając Zamówienie, Klient zobowiązuje się do podania prawdziwych i kompletnych danych wymaganych przez Platformę oraz akceptuje fakt, że Zamówienie wiąże się z obowiązkiem zapłaty."
            },
            {
                description: "W przypadku wyboru płatności gotówką przy odbiorze, Klient zobowiązany jest uiścić należność przy odbiorze Zamówienia w siedzibie Sprzedawcy lub osobie dostarczającej Zamówienie."
            },
            {
                description: "Sprzedawca dokumentuje sprzedaż paragonem fiskalnym, a na życzenie Klienta wystawia fakturę VAT."
            }
        ]
    },
    {
        title: "Prawo odstąpienia od umowy przez Klienta",
        points: [
            {
                description: "Klient ma prawo odstąpić od umowy bez podania przyczyny do momentu rozpoczęcia realizacji zamówienia przez Restauratora."
            },
            {
                description: "Aby skorzystać z prawa odstąpienia od umowy, Klient zobowiązuje się niezwłocznie poinformować o tym Restauratora po złożeniu zamówienia, jednak najpóźniej przed rozpoczęciem jego realizacji. Odstąpienie od umowy wymaga jednoznacznego oświadczenia w formie kontaktu telefonicznego z Restauratorem. Poinformowanie o odstąpieniu za pomocą poczty elektronicznej nie jest wystarczające do skutecznego odstąpienia."
            },
            {
                description: "Po rozpoczęciu realizacji zamówienia przez Restauratora, Klient nie może odwołać ani zmienić zamówienia, chyba że Restaurator wyrazi na to zgodę."
            },
            {
                description: "W przypadku, gdy Klient dokonał płatności online, Restaurator zobowiązuje się zwrócić środki niezwłocznie po otrzymaniu zlecenia zwrotu, nie później niż w ciągu 14 dni roboczych."
            }
        ]
    },
    {
        title: "Reklamacje",
        points: [
            {
                description: "Klient ma prawo złożyć reklamację dotyczącą zamówienia. Reklamacja powinna być wysłana na adres e-mail Restauratora: pl@captain-potato.com."
            },
            {
                description: "Reklamacja powinna zawierać następujące dane Klienta: imię, nazwisko, adres e-mail oraz numer telefonu kontaktowego."
            },
            {
                description: "Restaurator rozpatrzy reklamację w terminie do 14 dni od dnia jej otrzymania. W przypadku niekompletnych lub nieprawidłowych danych, termin ten może ulec przedłużeniu do momentu ich prawidłowego uzupełnienia."
            },
            {
                description: "Restaurator potwierdzi otrzymanie reklamacji na podany przez Klienta adres e-mail."
            }
        ]
    },
    {
        title: "Kodeks dobrych praktyk",
        points: [
            {
                description: "Zapewniamy pełną przejrzystość w zakresie oferowanych produktów i usług. Wszystkie informacje dotyczące składników posiłków, ich cen, dostępnych promocji oraz ewentualnych alergenów są wyraźnie przedstawione na naszej Platformie. Klient ma prawo do pełnej wiedzy o tym, co zamawia, w tym składzie posiłków oraz warunkach promocji."
            },
            {
                description: "Dbamy o najwyższe standardy sanitarno-epidemiologiczne w przygotowywaniu posiłków. Regularnie przeprowadzamy kontrolę jakości, aby zapewnić, że wszystkie posiłki są bezpieczne dla zdrowia. Nasz personel jest odpowiednio przeszkolony w zakresie higieny i bezpieczeństwa żywności."
            },
            {
                description: "Gwarantujemy rzetelne przedstawianie ofert i promocji. Wszelkie rabaty i promocje są jasno określone, a ich zasady są dostępne dla klientów przed dokonaniem zakupu. Nie stosujemy ukrytych kosztów ani nieprawdziwych reklamacji."
            },
            {
                description: "Wszelkie skargi i reklamacje są traktowane z najwyższą powagą. Oferujemy klientom szybkie i skuteczne rozwiązania, aby zapewnić ich satysfakcję. Nasza procedura reklamacyjna jest klarowna, a każdy klient może liczyć na uczciwe rozpatrzenie jego zgłoszenia."
            },
            {
                description: "Ściśle przestrzegamy przepisów o ochronie danych osobowych. Wszystkie dane klientów są bezpiecznie przechowywane i wykorzystywane zgodnie z naszą Polityką Prywatności. Dbamy o to, aby dane osobowe były chronione przed nieuprawnionym dostępem i wykorzystywane wyłącznie w celu realizacji zamówień."
            }
        ]
    },
    {
        title: "Postanowienia końcowe",
        points: [
            {
                description: "W sprawach nieuregulowanych Regulaminem stosuje się przepisy powszechnie obowiązującego prawa."
            },
            {
                description: "Restaurator zastrzega sobie prawo do zmiany Regulaminu. Zmiany wchodzą w życie po upływie 2 dni od dnia ich publikacji na Platformie. Kontynuowanie korzystania z Platformy po wprowadzeniu zmian oznacza akceptację nowego brzmienia Regulaminu. W przypadku braku akceptacji zmian, Klient nie będzie mógł korzystać z Platformy."
            },
            {
                description: "Regulamin nie wyłącza ani nie ogranicza uprawnień Klienta będącego konsumentem, które przysługują mu na mocy bezwzględnie obowiązujących przepisów prawa. W przypadku sprzeczności pomiędzy postanowieniami Regulaminu a bezwzględnie obowiązującymi przepisami prawa przyznającymi konsumentom uprawnienia, pierwszeństwo mają te przepisy."
            },
            {
                description: "W ramach korzystania z Usług niedozwolone jest dostarczanie przez Użytkownika oraz Klienta treści o charakterze bezprawnym, w tym:",
                subs: [
                    "informacji i danych, które mogą stwarzać ryzyko naruszenia bezpieczeństwa informatycznego lub stabilności Platformy;",
                    "informacji naruszających prawa własności intelektualnej, w tym prawa autorskie i prawa do znaków towarowych Restauratora lub osób trzecich;",
                    "innych informacji i danych naruszających bezwzględnie obowiązujące przepisy prawa."
                ]
            }
        ]
    }
]
