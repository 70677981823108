import { Typography } from 'components/Typography/Typography'
import { Logo } from './Logo'
import classes from './NotFound.module.scss'
import { Player } from './Player'

export const NotFound = () => {
    return (
        <div className={classes.NotFound}>
            <Typography format='t' paragraphClassname={classes.NotFound__Paragraph}>404</Typography>
            <Typography paragraphClassname={classes.NotFound__Paragraph}>Kliknij ziemniaka aby wrócić na główną</Typography>
            <Logo/>
            <Player/>
        </div>
    )
}