import { MenuButton } from 'lib/MenuButton/MenuButton'
import classes from './MainPageSection.module.scss'
import { Typography } from "components/Typography/Typography"

// now add menu button

export const MainPageSection = () => {
    return (
        <div className={classes.MainPageSection} id="home">
            <div className={classes.MainPageSection__TitleSection}>
                <Typography format="t" testID='main-page-title'>
                   Captain Potato Wieliczka
                </Typography>
                <MenuButton testId='main-page-menu-button'/>
            </div>
        </div>
    )
} 