import classNames from 'classnames'
import classes from './BaseSection.module.scss'

interface BaseSectionProps {
    lowerHeight?: boolean
    routeId?: string
    children: React.ReactNode
}

export const BaseSection = ({ children, lowerHeight, routeId }: BaseSectionProps) => {
    return (
        <div id={routeId} className={classNames(classes.BaseSection, lowerHeight ? classes.LowerHeight : '')}>
            {children}
        </div>
    )
}