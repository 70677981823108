import { Typography } from "components/Typography/Typography"
import classes from './PickupSelection.module.scss'

export const PickupSelection = () => { 
    return (
        <div className={classes.PickupSelection} data-testid='pickUp-content'>
            <div className={classes.PickupSelection__ExtraPadding}> 
                <Typography format="b" paragraphClassname={classes.Paragraph} testID="pickup-info">
                    Zamówienie będzie gotowe około 10 minut po złożeniu.
                    Proszę odebrać pod adresem:
                </Typography>
                <Typography format="b" bold paragraphClassname={classes.Paragraph} testID="pickup-address">
                    Rożnowska 7,<br/>
                    Wieliczka 32-020
                </Typography>
            </div>
        </div>
    )
}