import { Definitions } from "./types";

export const definitions: Definitions = {
    description: "W rozumieniu niniejszego Regulaminu poniższe sformułowania oznaczają:",
    points: [
        {
            title: "Regulamin",
            desc: "niniejszy regulamin;"
        }, 
        {
            title: "Platforma",
            desc: "system sprzedaży online prowadzony przez Sprzedawcę, dostępny pod adresem https://captain-potato.com, który umożliwia składanie zamówień oraz uzyskanie informacji o restauracji;"
        }, 
        {
            title: "Sprzedawca/Restaurator",
            desc: "Działalność gastronomiczna Piotr Kmiecik, z siedzibą pod adresem Rożnowska 7, 32-020 Wieliczka, NIP: 6832139668;"
        }, 
        {
            title: "Użytkownik",
            desc: "osoba fizyczna posiadająca pełną zdolność do czynności prawnych, tj. osoba pełnoletnia, jak również osoba fizyczna posiadająca ograniczoną zdolność do czynności prawnych (np. osoba pomiędzy 13 a 18 rokiem życia), o ile uzyska zgodę swojego przedstawiciela ustawowego;"
        }, 
        {
            title: "Usługi",
            desc: "wszelkie usługi świadczone drogą elektroniczną przez Sprzedawcę na rzecz Użytkowników, które obejmują możliwość zawierania umów sprzedaży online z Restauratorem;"
        }, 
        {
            title: "Świadczenie usług drogą elektroniczną",
            desc: "wykonywanie usług na odległość, za pośrednictwem urządzeń do elektronicznego przetwarzania danych, zgodnie z przepisami ustawy z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne;"
        }, 
        {
            title: "Umowa Sprzedaży",
            desc: "umowa zawarta pomiędzy Restauratorem a Użytkownikiem, dotycząca sprzedaży posiłków za pośrednictwem Platformy, zgodnie z przepisami Kodeksu cywilnego;"
        }, 
        {
            title: "Zamówienie",
            desc: "złożone przez Użytkownika oświadczenie woli, za pomocą formularza dostępnego na Platformie, mające na celu zawarcie Umowy Sprzedaży;"
        }, 
        {
            title: "Posiłek",
            desc: "produkty oferowane przez Restauratora, w tym posiłki, napoje oraz inne artykuły;"
        }, 
        {
            title: "Klient/Konsument",
            desc: "Użytkownik będący osobą fizyczną, który dokonuje czynności prawnej z Restauratorem niezwiązanej bezpośrednio z jego działalnością gospodarczą lub zawodową."
        }
    ]
}