import { CloseButton } from "lib/CloseButton/CloseButton"
import { SubmitOrderButton } from "./SubmitOrderButton"
import classes from './SubmitOrderButton.module.scss'

interface OrderCloseBasketBarProps {
    onClose: () => void
}

export const OrderCloseBasketBar = ({ onClose }: OrderCloseBasketBarProps) => {
    return (
        <div className={classes.AddCloseBar}>
            <CloseButton onClose={onClose} testID="basket-close-button"/>
            <SubmitOrderButton/>
        </div>
    )
}