import { Typography } from "components/Typography/Typography"
import { openingTimes } from "data/openingTimes"
import { useMemo, useState } from "react";
import moment from 'moment-timezone';
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from "react-icons/io";
import classes from './OpeningTimes.module.scss'
import { DayOfTheWeek } from "data/types";

export const OpeningTimes = () => {

    const [openDropdown, setOpenDropdown] = useState(true)

    const currentDay = moment().tz('Europe/Warsaw').day(); 
    const today = useMemo(() => {
        return openingTimes.find(day => day.day === currentDay);
    }, [currentDay])

    const getOpeningTimesText = (day: DayOfTheWeek) => {
       return  day.isClosed ? 'Zamknięte' : `${day.times.start} - ${day.times.end}`
    }

    return (
        <div className={classes.OpeningTimes}>
        {openDropdown && today ? (
            <div>
                <Typography format="h3">
                    {today.name}: {getOpeningTimesText(today)}
                </Typography>
            </div>) : (
            <div>
                {openingTimes.map((day) => {
                    return (
                        <Typography format="h3" key={day.day}>
                            {day.name}: {getOpeningTimesText(day)}
                        </Typography>
                    )
                })}
            </div>
            )   
        }
        <div onClick={() => setOpenDropdown(!openDropdown)} className={classes.OpeningTimes__Arrow}>
            {openDropdown && today ?
                (<IoMdArrowDropdownCircle/>) :
                (<IoMdArrowDropupCircle/>)
            }
        </div>
        </div>
    )
}