import { useEffect, useRef } from "react";
import { useField } from "formik";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import classes from './AutocompleteAddress.module.scss';
import './autocompleteStyles.css';

const AutocompleteInput = () => {
  const [addressField, , addressHelpers] = useField('address');
  const { setValue: setAddressValue } = addressHelpers;

  const [placeField, , placeHelpers] = useField('placeObject');
  const { setValue: setPlaceValue } = placeHelpers;

  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };

    const autocomplete = new places.Autocomplete(inputRef.current, options);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      setAddressValue(place.formatted_address); 
      setPlaceValue(place)
    });

    return () => {
      google.maps.event.clearInstanceListeners(autocomplete); 
    };
  }, [places, setPlaceValue, setAddressValue]);

  return (
    <div className={classes.InputField}>
      <input 
        ref={inputRef} 
        {...addressField} 
        placeholder="Wybierz adres" 
      />
    </div>
  );
};

export default AutocompleteInput;
