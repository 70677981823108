const ENV = process.env.REACT_APP_ENV;
const SHOW_AUTH_PROMPT = process.env.REACT_APP_ENV === 'development';

const PARAM = ENV === 'development' ? 'dev' : 'prod'

const AUTH_API = process.env.REACT_APP_AUTH_API;
const ORDER_API = process.env.REACT_APP_ORDER_API;
const SETTINGS_API = process.env.REACT_APP_SETTINGS_API;

const GM_API_KEY = process.env.REACT_APP_GM_API_KEY;

const LS_AUTH_KEY = process.env.REACT_APP_LOCAL_STORAGE_AUTH_KEY

export { ENV, SHOW_AUTH_PROMPT, AUTH_API, ORDER_API, PARAM, SETTINGS_API, GM_API_KEY, LS_AUTH_KEY };
