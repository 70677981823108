import { getDataByCategory, TabElement } from "data/menuListData";
import { BaseModal } from "../BaseModal/BaseModal";
import classes from "./AddItemModal.module.scss";
import { Typography } from "components/Typography/Typography";
import { imageMap } from "utils/imageMap";
import { Counter } from "components/Counter/Counter";
import { AddOns } from "./AddOns/AddOns";
import { CommentBox } from "components/CommentBox/CommentBox";
import { AddCloseBar } from "components/AddCloseBar/AddCloseBar";
import { Radios } from "./Radios/Radios";
import { divStyle } from "./utils";
import { Form, Formik } from "formik";
import { useItemContext } from "context/ItemContext";
import { ItemsWithPrices } from "types/orderFormat";
import { useAddItemToOrder } from "hooks/useAddItemToOrder";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloseButton } from "lib/CloseButton/CloseButton";

interface AddItemModalProps {
  onClose: () => void;
  itemCategory: string;
  itemTitle: string;
}

export const AddItemModal = ({
  itemCategory,
  itemTitle,
  onClose,
}: AddItemModalProps) => {
  const { item: currentItem, updateItem } = useItemContext();
  const { addItemToOrder } = useAddItemToOrder();

  const items: TabElement | undefined = getDataByCategory(itemCategory);
  const item = items?.items.find((element) => element.title === itemTitle);

  if (!item) {
    return null;
  }

  const initialValues: ItemsWithPrices = {
    id: uuidv4(),
    name: itemTitle,
    quanity: 1,
    radios: currentItem.radios || [],
    addOns: currentItem.addOns || [],
    comment: currentItem.comment || "",
    price: 0,
    cost: {
      startingPrice: item?.price || 0,
      addOns: 0,
      radios: 0,
    },
  };

  const addOns = item.itemInfo.addOns;
  const radios = item.itemInfo.radios;
  const imageUrl =
    imageMap[item.itemInfo.imageUrl] || "~assets/photos/temp/many-potatoes.jpg";

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          addItemToOrder(values);
          onClose();
          updateItem();
          toast.success("Dodano do koszyka!");
        }}
      >
        <Form>
          <BaseModal
            onClose={onClose} 
            testID="add-item-modal"
            classname={classes.AddItemModal}
          >
            <div className={classes.AddItemModal__CloseButtonRow}>
              <CloseButton onClose={onClose} className={classes.AddItemModal__CloseButton}/>
            </div>
            <div className={classes.AddItemModal__Layout}>
              <div className={classes.AddItemModal__ScrollableContainer}>
                <div
                  className={classes.AddItemModal__Image}
                  data-testid="menu-item-image"
                >
                  <div style={divStyle(imageUrl)}></div>
                </div>
                <div className={classes.AddItemModal__Section}>
                  <Typography format="h1" bold testID="menu-item-title">
                    {item.title.toUpperCase()}
                  </Typography>
                  <Typography format="b" data-testid="menu-item-desc">
                    {item.itemInfo.fullDesc}
                  </Typography>
                  <Counter />
                </div>
                <hr />
                <div className={classes.AddItemModal__Section}>
                  {addOns && addOns.size > 0 && <AddOns items={addOns} />}
                  {radios && radios.length > 0 && <Radios items={radios} />}
                  <CommentBox testID="addItem-comment-box" />
                </div>
              </div>
              <div className={classes.AddItemModal__FixedContainer}>
                <AddCloseBar onClose={onClose} />
              </div>
            </div>
          </BaseModal>
        </Form>
      </Formik>
    </>
  );
};
