import { createContext, useContext } from 'react';
import { ItemInBasket, OrderFormat } from 'types/orderFormat';

interface OrderContextType {
  order: OrderFormat | undefined;
  setExistingOrder: (order: OrderFormat) => void
  addItem: (item: ItemInBasket) => void 
  removeItem: (item: ItemInBasket) => void
}

export const OrderContext = createContext<OrderContextType | undefined>(undefined);

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('orders must be used within a OrderContext');
  }
  return context;
};