import { MainPageSection } from "sections/MainPage/MainPageSection";
import { AboutUsSection } from "sections/AboutUs/AboutUsSection";
import { MenuSection } from "sections/Menu/MenuSection";
import { ContactSection } from "sections/Contact/ContactSection";
import { TopBar } from "components/TopBar/TopBar";
import { Vibe } from "sections/Vibe/Vibe";
import { Footer } from "sections/Footer/Footer";
import { useEffect, useState } from "react";
import { SHOW_AUTH_PROMPT } from "./config";
import { AuthPage } from "pages/Login/AuthPage";
import useWindowDimensions from "hooks/useWindowDimensions";
import { MobileTopBar } from "components/TopBar/MobileTopBar";
import { useNavigate } from "react-router-dom";
import { useOpeningTimesAction } from "effects/useOpeningTimesActions";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!SHOW_AUTH_PROMPT);
  const [openSheet, setOpenSheet] = useState(false);
  const { width } = useWindowDimensions();
  const { checkIsOpen } = useOpeningTimesAction()
  const navigate = useNavigate();

  useEffect(() => {
    if (openSheet) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [openSheet]);

  useEffect(() => {
    navigate("/home");
    checkIsOpen({ showAdditionalToasts: true })
  }, []);

  return (
    <div>
      {isAuthenticated ? (
        <>
          {width > 640 ? (
            <TopBar />
          ) : (
            <MobileTopBar openSheet={openSheet} setOpenSheet={setOpenSheet} />
          )}
          <MainPageSection />
          <AboutUsSection />
          <MenuSection />
          <Vibe />
          <ContactSection />
          <Footer />
        </>
      ) : (
        <AuthPage onAuthenticate={(value: boolean) => setIsAuthenticated(value)} />
      )}
    </div>
  );
}

export default App;
