import { useState } from "react"
import { PiMinusCircleFill , PiPlusCircleFill } from "react-icons/pi";
import classes from './Counter.module.scss'
import { Typography } from "components/Typography/Typography";
import { useFormikContext } from "formik";
import { ItemInBasket } from "types/orderFormat";

export const Counter = () => {
    const [count, setCount] = useState(1)
    const { setFieldValue } = useFormikContext<ItemInBasket>()

    const minusCount = () => {
        if(count > 1) {
            const newCount = count - 1
            setCount(newCount)
            setFieldValue('quanity', newCount)
        } 
    }

    const plusCount = () => {
        if(count < 16) {
            const newCount = count + 1
            setCount(newCount)
            setFieldValue('quanity', newCount)
        } 
    }

    return (
        <div className={classes.Counter}>
            <PiMinusCircleFill onClick={minusCount} className={count > 1 ? classes.Active : classes.Disabled} data-testid='menu-item-remove-one'/>
            <Typography format="b" bold testID='menu-item-counter'>{count}</Typography>
            <PiPlusCircleFill onClick={plusCount} className={count < 16 ? classes.Active : classes.Disabled} data-testid='menu-item-add-one'/>
        </div>
    )
}