export const scrollWithOffset = (el: HTMLElement) => {
	// const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
	// const yOffset = -50; // Adjust this value based on the height of your fixed header
	// window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const elementHeight = el.clientHeight;
  let yOffset = 0;

  if (elementHeight === window.innerHeight) {
    // For 100vh elements, apply the standard offset
    yOffset = -50; // Adjust based on your header height
  } else if (elementHeight < window.innerHeight) {
    // For elements less than 100vh, scroll to top with a smaller or no offset
    yOffset = -300;
  }

  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }