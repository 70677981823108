import { deliveryTimes } from "data/deliveryTimes";
import { ENV } from 'config'
const moment = require('moment-timezone')

export const isTheShopOpen = () => {
    const currentDay = moment().tz('Europe/Warsaw').day()
    const currentTime = moment().tz('Europe/Warsaw') 

    const today = deliveryTimes[currentDay]

    if(ENV !== 'production') {
        return true;
    }

    if (today.isClosed) {
        return false
    }

    const hours = today.times
    if (!hours) {
        return false
    }

    const openTime = moment.tz(hours.start, "HH:mm", 'Europe/Warsaw');
    const closeTime = moment.tz(hours.end, "HH:mm", 'Europe/Warsaw');

    return currentTime.isBetween(openTime, closeTime);
}