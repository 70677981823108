import { Typography } from "components/Typography/Typography";
import { TCsCheckbox } from "./TCsCheckbox";
import classes from "../BasketModal.module.scss";

export const TCsRow = () => {
  return (
    <div className={classes.BasketModal__Checkbox}>
      <TCsCheckbox />
      <Typography
        format="h3"
        paragraphClassname={classes.BasketModal__Paragraph}
      >
        Akceptuję{" "}
        <a
        href="/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.BasketModal__Link}>
          regulamin
        </a>
      </Typography>
    </div>
  );
};
