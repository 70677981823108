import { ReactNode, useState } from "react";
import { ItemInBasket } from "types/orderFormat";
import { ItemContext } from "./ItemContext";

const emptyItem: ItemInBasket = {
    id: "",
    name: "",
    quanity: 1,
    radios: [],
    addOns: [],
    comment: "",
    price: 0
}

export const ItemProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [item, setItem] = useState<ItemInBasket>(emptyItem);

    const updateItem = () => {
          setItem(emptyItem)
    };
    
    return (
      <ItemContext.Provider
      value={{
        item,
        updateItem
      }}
      >
        {children}
      </ItemContext.Provider>
    );
  };