import { AddToOrderButton } from "lib/AddToOrderButton/AddToOrderButton"
import { CloseButton } from "lib/CloseButton/CloseButton"
import classes from './AddCloseBar.module.scss'
import { useEffect, useMemo } from "react"
import { useFormikContext } from "formik"
import { ItemsWithPrices } from "types/orderFormat"

interface AddCloseBarProps {
    onClose: () => void
}

export const AddCloseBar = ({ onClose }: AddCloseBarProps) => {
    const { values, setFieldValue } = useFormikContext<ItemsWithPrices>()

    const totalPrice = useMemo(() => {
        return (values.cost.startingPrice + values.cost.addOns + values.cost.radios)*values.quanity
    }, [values.cost.startingPrice, values.cost.addOns, values.cost.radios, values.quanity])

    useEffect(() => {
        setFieldValue('price', totalPrice);
    }, [totalPrice, setFieldValue]);

    return (
        <div className={classes.AddCloseBar}>
            <CloseButton onClose={onClose} testID="menu-item-close-button"/>
            <AddToOrderButton price={totalPrice}/>
        </div>
    )
}