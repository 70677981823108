import { Typography } from 'components/Typography/Typography'
import classes from './Footer.module.scss'
import { FooterLogo } from "./FooterLogo/FooterLogo"
import { FaFacebook } from "react-icons/fa";
import { Link } from 'components/Link/Link';
import { FooterMenu } from './FooterMenu/FooterMenu';
import { useNavigate } from 'react-router';

export const Footer = () => {
    const navigate = useNavigate()

    const navigateToTandCs = () => {
        navigate('/terms-and-conditions')
        window.scrollTo(0, 0)
    }

    const navigateToPrivacyPolicy = () => {
        navigate('/privacy-policy')
        window.scrollTo(0, 0)
    }

    return (
        <div className={classes.Footer}>
            <Link anchor="home">
                <FooterLogo/>
            </Link>

            <FooterMenu/>

            <a href="https://www.facebook.com/profile.php?id=61564105397259" target="_blank" rel="noopener noreferrer">
                <FaFacebook className={classes.Icon}/>
            </a>

            <Typography format='b'>
                <span className={classes.Footer__LegalRow}>
                <span onClick={navigateToTandCs}>
                    Regulamin
                </span>
                |
                <span onClick={navigateToPrivacyPolicy}>
                    Polityka Prywatności
                </span>
                </span>
            </Typography>
        </div>
    )
}