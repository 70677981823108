import classes from './NotFoundLogo.module.scss'
import { useNavigate } from 'react-router';

export const Logo = () => {
    const navigate= useNavigate()
    return (
        <div className={classes.container} onClick={() => navigate('/home')}>
          <div className={classes.logo}></div>
        </div>
    )
}