import { Typography } from "components/Typography/Typography";
import { ClipLoader } from "react-spinners";
import classes from './LoadingOverlay.module.scss'

export const LoadingOverlay = () => {
  return (
    <div className={classes.LoadingOverlay}>
      <Typography format="h1">Przetwarzanie zamówienia</Typography>
      <ClipLoader
        color="#EAEAEA"
        loading={true}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
