import * as Yup from 'yup';

const containsJsKeyword = (value?: string) => {
  if (!value) {
    return false;
  }
  const jsKeywords = [
    'break',
    'case',
    'catch',
    'class',
    'const',
    'continue',
    'debugger',
    'default',
    'delete',
    'double',
    'else',
    'enum',
    'export',
    'extends',
    'false',
    'finally',
    'for',
    'function',
    'if',
    'implements',
    'import',
    'instanceof',
    'interface',
    'let',
    'new',
    'null',
    'return',
    'super',
    'switch',
    'this',
    'throw',
    'try',
    'true',
    'typeof',
    'var',
    'void',
    'while',
    'with',
    'yield'
  ];
  return jsKeywords.some((keyword) => value.includes(keyword));
};

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required()
    .test((value) => !containsJsKeyword(value)),
  password: Yup.string()
    .required()
    .test((value) => !containsJsKeyword(value))
});
