import axios from "axios";
import { AUTH_API, ENV } from "../config";
import { useState } from "react";

export const useAuthenticate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authenticate = async (username: string, password: string) => {
    if (ENV === "local") {
      return 200;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${AUTH_API}/getCreds`,
        {
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.status;
    } catch (e) {
      console.error(e);
      return 500;
    } finally {
      setIsLoading(false);
    }
  };

  return { authenticate, isLoading };
};
