import { Typography } from "components/Typography/Typography";
import classes from "../Terms.module.scss";

export const SellerData = () => {
  return (
    <span>
      <Typography format="h1" bold>1. Dane Sprzedawcy</Typography>
      <Typography format="h3" paragraphClassname={classes.Paragraph} bold>
        Captain Potato <br />
        Rożnowska 7, <br />
        32-020 Wieliczka <br />
      </Typography>
      <Typography format="h3" paragraphClassname={classes.Paragraph}>
        NIP: 6832139668 <br />
        REGON: 529374610
        <br />
        Adres e-mail: pl@captain-potato.com <br />
        Numer telefonu:. +48 664 138 905 (stawka według opłat operatora
        dzwoniącego)
      </Typography>
    </span>
  );
};
