import { IoMdCheckbox } from "react-icons/io"
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import classes from './Checkbox.module.scss'
import { useField } from "formik";

export const Checkbox = () => {
    const [field, , helpers] = useField('addCutlery');
    const { setValue } = helpers;

    const onCheck = () => {
        setValue(true);
    };

    const onUncheck = () => {
        setValue(false);
    };

    return (
        <div className={classes.Checkbox} data-testid='add-cutlery-checkbox'>
            {field.value ? (
                <IoMdCheckbox onClick={onUncheck} />
            ) : (
                <MdCheckBoxOutlineBlank onClick={onCheck} />
            )}
        </div>
    );
}