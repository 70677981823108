import { Typography } from "components/Typography/Typography"
import classes from './CommentBox.module.scss'
import { useFormikContext } from "formik"
import { ItemInBasket } from "types/orderFormat"

interface CommentBoxProps {
    testID: string
}

export const CommentBox = ({ testID }: CommentBoxProps) => {
    const { setFieldValue } = useFormikContext<ItemInBasket>()

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFieldValue('comment', event.target.value)
    };
    
    return (
        <div className={classes.CommentBox} data-testid={testID}>
            <Typography format="h2" bold>Komentarz</Typography>
            <textarea rows={4} className={classes.CommentBox__TextArea} placeholder="Opcjonalny komentarz" onChange={handleChange}></textarea>
        </div>
    )
}