import { TopBar } from "components/TopBar/TopBar";
import { Footer } from "sections/Footer/Footer";
import classes from "./BasePage.module.scss";
import useWindowDimensions from "hooks/useWindowDimensions";
import { MobileTopBar } from "components/TopBar/MobileTopBar";
import { useState } from "react";

interface BasePageProps {
  children: React.ReactNode;
}

export const BasePage = ({ children }: BasePageProps) => {
  const { width } = useWindowDimensions();
  const [openSheet, setOpenSheet] = useState(false);
  
  return (
    <div>
      {width > 640 ? (
        <TopBar />
      ) : (
        <MobileTopBar openSheet={openSheet} setOpenSheet={setOpenSheet} />
      )}
      <div className={classes.FullPage}>
        <div className={classes.AllPoints}>{children}</div>
      </div>
      <Footer />
    </div>
  );
};
