import { Typography } from "components/Typography/Typography";
import classes from "./SharedFields.module.scss";
import { InputField } from "../InputField/InputField";

export const SharedFields = () => {
    return (
        <div className={classes.SharedFields} data-testid="shared-content">
          <Typography format="b" bold testID="delivery-personalInfo-input-label">
            Prosze podać dane informacje
          </Typography>
          <div className={classes.SharedFields__InputGroup}>
            <InputField
              nameID="firstName"
              title="Imię"
              testId="pickup-name-input-field"
            />
            <InputField
              nameID="lastName"
              title="Nazwisko"
              testId="pickup-name-input-field"
            />
            <InputField
              nameID="phoneNumber"
              title={"Numer telefonu"}
              testId="delivery-phone-input-field"
            />
            <InputField
              nameID="email"
              title={"Email"}
              testId="delivery-email-input-field"
            />
          </div>
          </div>
    )
}