import { ItemProvider } from "context/ItemProvider";
import { OrderProvider } from "context/OrderProvider";
import { SettingsProvider } from "context/SettingsTimeProvider";

interface ProvidersProps {
  children: React.ReactNode;
}

export const Providers = ({ children }: ProvidersProps) => {
  return (
    <OrderProvider>
      <ItemProvider>
        <SettingsProvider>{children}</SettingsProvider>
      </ItemProvider>
    </OrderProvider>
  );
};
