import { TiThMenu } from "react-icons/ti";
import { Logo } from "components/Logo/Logo";
import classes from "./MobileTopBar.module.scss";
import { IoCloseCircleSharp } from "react-icons/io5";
import { Typography } from "components/Typography/Typography";
import { Link } from "components/Link/Link";
import { useHandleScroll } from "hooks/useHandleScroll";
import classNames from "classnames";

interface MobileTopBarProps {
    openSheet: boolean
    setOpenSheet: (toggle: boolean) => void
}

export const MobileTopBar = ({ openSheet, setOpenSheet }: MobileTopBarProps) => {
    const { isScrolling } = useHandleScroll()
    const closeModal = () => {
        setOpenSheet(false)
    }
  return (
    <div className={classNames(
        classes.MobileTopBar,
        isScrolling && classes.MobileTopBar__ScrolledDown
    )}>
      <Logo />
      {!openSheet && (
        <div onClick={() => setOpenSheet(true)} className={classes.MobileTopBar__Icon}>
          <TiThMenu  style={{ width: '25px' }}/>
        </div>
      )}
      {openSheet && (
          <div className={classes.MobileSheet}>
            <IoCloseCircleSharp onClick={closeModal} />
                <div className={classes.MobileSheet__List}>
                <Typography format="h2" bold>
						<Link anchor='about-us' onClick={closeModal}>O NAS</Link>
					</Typography>
                <Typography format="h2" bold>
						<Link anchor='vibe' onClick={closeModal}>GALERIA</Link>
					</Typography>
					<Typography format="h2" bold>
						<Link anchor='contact' onClick={closeModal}>KONTAKT</Link>
					</Typography>
                    <Typography format="h2" bold>
						<Link anchor='menu' onClick={closeModal}>ZAMÓW TERAZ</Link>
					</Typography>
                </div>
          </div>
      )}
    </div>
  );
};
