import classes from './AuthPage.module.scss'
import { ErrorMessage, Field, Form, Formik } from "formik"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useAuthenticate } from 'hooks/useAuthenticate'
import { Typography } from 'components/Typography/Typography'
import { validationSchema } from 'pages/Login/authValidationSchema'

interface AuthPageProps {
  onAuthenticate: (authSuccessful: boolean) => void
}

interface AuthProps {
  username: string
  password: string
}

const initVals = {
  username: "",
  password: "",
}

export const AuthPage = ({ onAuthenticate }: AuthPageProps) => {
  const { authenticate, isLoading } = useAuthenticate()

  const onSubmit = async (values: AuthProps) => {
    const status = await authenticate(values.username, values.password)

    if (status === 200) {
        toast("Authenticated")
        onAuthenticate(true)
    } else if (status === 401) {
        toast("Authentication failed - invalid creds")
    } else {
        toast("Authentication failed - internal error")
    }
  }
  return (
    <div className={classes.AuthPage}>
      <Formik
        initialValues={initVals}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, validateForm }) => {
          validateForm().then((errors) => {
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            }
          });
          setSubmitting(false);
        }}
      >
        <Form className={classes.AuthPage__Form}>
        <div>
            <Field placeholder="Username" name="username" />
            <ErrorMessage name="username">
              {(msg) => <Typography paragraphClassname={classes.AuthPage__ErrorMessage}>{msg}</Typography>}
            </ErrorMessage>
            </div>
            <div>
            <Field placeholder="Password" type="password" name="password" />
            <ErrorMessage name="password">
              {(msg) => <Typography paragraphClassname={classes.AuthPage__ErrorMessage}>{msg}</Typography>}
            </ErrorMessage>
            </div>
          <button type="submit" disabled={isLoading} className={classes.AuthPage__Button}>
            Submit
          </button>
        </Form>
      </Formik>
    </div>
  )
}
