import { useOrderContext } from "context/OrderContext"
import { useCallback } from "react"
import { ItemInBasket, ItemsWithPrices } from "types/orderFormat"

export const useAddItemToOrder = () => {
    const { addItem } = useOrderContext()

    const addItemToOrder = useCallback((item: ItemsWithPrices) => {
        const itemInBasket: ItemInBasket = {
            id: item.id,
            name: item.name,
            quanity: item.quanity,
            radios: item.radios,
            addOns: item.addOns,
            comment: item.comment,
            price: item.price
        };

        addItem(itemInBasket)
    }, [addItem])

    return { addItemToOrder }
}