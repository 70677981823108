import { Typography } from "components/Typography/Typography"
import { MenuItem } from "data/menuListData"
import { OpenItemModalButton } from "lib/OpenItemModalButton/OpenItemModalButton"
import classes from './MenuList.module.scss'
import classNames from "classnames"

interface MenuListProps {
    items: MenuItem[]
    title: string
}

export const MenuList = ({items, title}: MenuListProps) => {
    return (
        <div data-testid='menu-tab-content-items'>
            {items.map((item, index) => {
                const isLastItem = index === items.length - 1;
                const itemKey = `${title}--${item.title}`
                const itemTestId = `menu-item-${item.id}`
                const buttonTestId = `menu-item-add-button-${item.id}`
                return (
                    <div key={itemKey} className={classNames(classes.MenuList, isLastItem ? classes.NoBorder : '')} data-testid={itemTestId}>
                        <div className={classes.MenuList__Text}>
                            <Typography format="h1" bold>{item.title}</Typography>
                            <Typography format="h3">{item.desc}</Typography>
                        </div>
                        <OpenItemModalButton itemCategory={title} itemTitle={item.title} price={item.price} testId={buttonTestId}/>
                    </div>
                )
            })}
        </div>
    )
}