import { Typography } from "components/Typography/Typography"

import classes from '../Terms.module.scss'
import { definitions } from "legal/definitions"

export const Definitions = () => {
    return (
        <span>
            <Typography format="h1" bold>2. {definitions.description}</Typography>
            {definitions.points.map((point) => {
                return (
                    <Typography format="h3" paragraphClassname={classes.Paragraph}><b>{point.title}</b> - {point.desc}</Typography>
                )
            })}
        </span>
    )
}