import { Typography } from "components/Typography/Typography";
import classes from "./CommentBox.module.scss";
import { useField } from "formik";

interface CommentBoxProps {
  testID?: string
}

export const CommentBox = ({ testID }: CommentBoxProps) => {
  const [field, meta] = useField("comment");

  return (
    <div className={classes.CommentBox} data-testid={testID}>
      <Typography format="h3" bold>
        Komentarz
      </Typography>
      <textarea
        {...field}
        rows={4}
        className={classes.CommentBox__TextArea}
        placeholder="Opcjonalny komentarz"
      />
      {meta.touched && meta.error ? (
        <Typography format="b" paragraphClassname={classes.CommentBox__ErrorMessage}>{meta.error}</Typography>
      ) : null}
    </div>
  );
};
