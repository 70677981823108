import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import classes from './Map.module.scss'

export default function Map() {
  return (
    <MapContainer center={[49.974, 20.056]} zoom={19} scrollWheelZoom={false} className={classes.MapAlt}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[49.974, 20.056]}>
        <Popup>
          Captain Potato
        </Popup>
      </Marker>
    </MapContainer>
  );
}