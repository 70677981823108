import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from "utils/scrollWithOffset";
import classes from './Link.module.scss'

interface LinkProps {
    children: React.ReactNode
    anchor: string
    onClick?: () => void
}

export const Link = ({ children, anchor, onClick }: LinkProps) => {
    const dynamicPath = `/home#${anchor}`
    return (
        <HashLink smooth to={dynamicPath} scroll={scrollWithOffset} className={classes.Link} onClick={onClick}>
            {children}
        </HashLink>
    )
}