import { Typography } from 'components/Typography/Typography'
import classes from './OrderOnlineButton.module.scss'
import { Link } from 'components/Link/Link'

export const OrderOnlineButton = () => {
    return (
        <Link anchor='menu'>
            <div className={classes.OrderOnlineButton} data-testid="order-online-button">
            <Typography format="h3" bold>
                ZAMÓW ONLINE
            </Typography>
            </div>
        </Link>
    )
}