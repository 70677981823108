export const commonAddOns =  new Map<string, number>([
    ["Bez extra dodatków", 0.00],
    ["Cebulka prażona", 2.00],
    ["Jalapeño", 2.00],
    ["Szczypiorek", 2.00],
    ["Ketchup", 2.00],
    ["Sos czosnkowy", 2.00],
    ["Sos serowy", 2.00]
])

export const commonExclusionStatements = [
    "Bez extra dodatków", "Bez dodatków", "Bez sosu", "Standard"
]