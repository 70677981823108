import { BaseSection } from 'sections/BaseSection/BaseSection'
import classes from './MenuSection.module.scss'
import { Typography } from "components/Typography/Typography"
import { MenuTabs } from 'components/MenuTabs/MenuTabs'

export const MenuSection = () => {
    return (
        <BaseSection lowerHeight routeId='menu'>
            <div className={classes.MenuSection}>
            <Typography format="t" testID='menu-title'>
                Nasze Menu
            </Typography>
            <MenuTabs/>
            </div>
        </BaseSection>
    )
} 