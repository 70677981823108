import classes from "./Typography.module.scss";
import classnames from "classnames";
import { getTextSize } from "./utils";

interface TypographyProps {
  bold?: boolean;
  format?: string;
  classname?: string;
  paragraphClassname?: string;
  testID?: string;
  children: React.ReactNode;
}

export const Typography = ({
  bold = false,
  format,
  classname,
  paragraphClassname,
  children,
  testID,
}: TypographyProps) => {
  const stylingClass = `Typography__${getTextSize(format ?? "h2")}`;
  const isTitle = format === "t" || format === "h1";
  const isH2 = format === "h2";

  return (
    <div
      className={classnames(classes.Typography, classname)}
      data-testid={testID}
    >
      {isTitle && (
        <h1
          className={classnames(
            classes[stylingClass],
            bold && classes.bold,
            paragraphClassname
          )}
        >
          {children}
        </h1>
      )}
      {isH2 && (
        <h2
          className={classnames(
            classes[stylingClass],
            bold && classes.bold,
            paragraphClassname
          )}
        >
          {children}
        </h2>
      )}
      {!isTitle && !isH2 && (
        <p
          className={classnames(
            classes[stylingClass],
            bold && classes.bold,
            paragraphClassname
          )}
        >
          {children}
        </p>
      )}
    </div>
  );
};
