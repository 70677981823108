import { DeliveryType, OrderFormValues } from "components/Modals/BasketModal/types"
import { useOrderContext } from "context/OrderContext"
import { useCreateOrder } from "hooks/payu/useCreateOrder"
import { useGetIP } from "hooks/useGetIP"
import { createOrderRequest } from "utils/convertToOrderRequest"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ENV } from "config"

export const useRequestPaymentAction = () => {
    const { getIP } = useGetIP()
    const { order } = useOrderContext()
    const { createOrder } = useCreateOrder()

    const requestOrder = async (values: OrderFormValues) => {
        const addressIP = await getIP()

        if(!addressIP || !order?.items || order.fullPrice < 1) {
            return
        }
        if(values.type === DeliveryType.DELIVERY && order.fullPrice < 40) {
            toast.error('Minimalna kwota zamówienia na dowóz to 40 zł.')
            return
        }
        const orderRequest = createOrderRequest(values, addressIP, order)
        
        if(ENV === 'local') {
            return
        }
        
        await createOrder(orderRequest)
            .then(async (response) => {
                if(response?.redirectURL) {
                    localStorage.clear()
                    const url = `${response.redirectURL}&lang=pl`
                    window.location.href = url
                } else {
                    toast.error('Niestety nie udało nam się przetworzyć zamówienia. Prosimy spróbować później.')
                    return
                }
            })
    }

    return { requestOrder }
}