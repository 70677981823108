import { BaseSection } from 'sections/BaseSection/BaseSection'
import classes from './AboutUsSection.module.scss'
import { AboutUsText } from './AboutUsText/AboutUsText'
import { PhotoCollage } from './PhotoCollage/PhotoCollage'

export const AboutUsSection = () => {
    return (
        <BaseSection routeId='about-us'>
            <div className={classes.AboutUsSection}>
                <PhotoCollage/> 
                <AboutUsText/>
            </div>
        </BaseSection>
    )
} 