import * as Yup from 'yup';

 export const divStyle = (imageUrl: string) => {
    return {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '15px 15px 0px 0px',
        width: 'inherit',
        minHeight: 'inherit'
    }
  };

  export const itemValidationSchema = Yup.object().shape({
    
  })