export const getTextTypeByViewport = (width: number) => {
    return width > 800 ? 'Desktop' : 'Mobile'
}

export const getTextSize = (format: string) => {
    switch(format) {
        case 't': return 'Title'
        case 'h1': return 'Heading1'
        case 'h2': return 'Heading2'
        case 'h3': return 'Heading3'
        case 'b': return 'BodyText'
    }
}