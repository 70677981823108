import { Link } from "components/Link/Link"
import { Typography } from "components/Typography/Typography"
import classes from './FooterMenu.module.scss'

export const FooterMenu = () => {
    return (
        <div className={classes.FooterMenu}>
            <Link anchor={"home"}>
                <Typography paragraphClassname={classes.Paragraph} testID="footer-mainPage-link">
                    GŁÓWNA
                </Typography>
            </Link>

            <Link anchor={"about-us"}>
                <Typography paragraphClassname={classes.Paragraph} testID="footer-aboutUs-link">
                    O NAS
                </Typography>
            </Link>

            <Link anchor={"menu"}>
                <Typography paragraphClassname={classes.Paragraph} testID="footer-menuPage-link">
                    MENU
                </Typography>
            </Link>

            <Link anchor={"vibe"}>
                <Typography paragraphClassname={classes.Paragraph} testID="footer-vibePage-link">
                    GALERIA
                </Typography>
            </Link>

            <Link anchor={"contact"}>
                <Typography paragraphClassname={classes.Paragraph} testID="footer-contactPage-link">
                    KONTAKT
                </Typography>
            </Link>
        </div>
    )
}