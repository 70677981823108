export const imageMap: { [key: string]: string } = {
    'jacket-potato': require('assets/photos/temp/jacket-potato.jpeg'),
    'kapitan': require('assets/photos/potatoes/kapitana.JPG'),
    'pirat': require('assets/photos/potatoes/pirata.JPG'),
    'bossman': require('assets/photos/potatoes/bossmana.JPG'),
    'marynarz': require('assets/photos/potatoes/marynarza.JPG'),
    'sternik': require('assets/photos/potatoes/sternika.JPG'),
    'korsarz': require('assets/photos/potatoes/korsarza.JPG'),
    'zeglarz': require('assets/photos/potatoes/zeglarza.JPG'),
    'nachos-base': require('assets/photos/nachos/cheese.JPG'),
    'nachos-meat': require('assets/photos/nachos/meat.JPG'),
    'nachos-vegan': require('assets/photos/nachos/vegan.JPG'),
    'black-pearl': require('assets/photos/others/black-pearl.JPG'),
    'cola': require('assets/photos/others/cola.JPG'),
    'sprite': require('assets/photos/others/sprite.JPG'),
    'lemonade': require('assets/photos/others/lemoniada.JPG'),
};