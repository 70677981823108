import { Point } from "./types";

export const privacyPolicyData: Point[] = [
    {
        title: "Informacje ogólne",
        desc: "Administrator danych osobowych użytkowników serwisu captain-potato.com.",
        points: [
            {
                description: "Na podstawie art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (zwanego dalej RODO), Captain Potato informuje niniejszym iż: Administratorem Pani/Pana danych osobowych jest Captain Potato, Piotr Kmiecik, z siedzibą pod adresem Rożnowska 7, 32-020 Wieliczka, NIP: 6832139668, zwana dalej Operatorem Serwisu."
            },
            {
                description: "Serwis captain-potato.com realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:",
                subs: [
                    "Poprzez dobrowolnie wprowadzone w formularzach informacje.",
                    "Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”)."
                ]
            },
        ]
    },
    {
        title: "Informacje w formularzach",
        points: [
            {
                description: "Serwis zbiera informacje dobrowolnie podane przez użytkownika."
            },
            {
                description: "Serwis może zapisywać dodatkowe informacje o parametrach połączenia, takie jak oznaczenie czasu oraz adres IP.",
            },
            {
                description: "Dane podane w formularzach nie są udostępniane podmiotom trzecim bez wyraźnej zgody użytkownika."
            },
            {
                description: "Dane podane w formularzu kontaktowym mogą stanowić zbiór potencjalnych klientów, zarządzany przez Operatora Serwisu zgodnie z obowiązującymi przepisami o ochronie danych osobowych."
            },
            {
                description: "Dane podane w formularzach są przetwarzane w celach zgodnych z funkcją danego formularza, np. w celu obsługi zamówienia, dostawy do Klienta, rozpatrzenia reklamacji lub kontaktu handlowego."
            },
            {
                description: "Dane podane w formularzach mogą być przekazywane podmiotom realizującym techniczne usługi na rzecz Operatora Serwisu w zakresie obsługi zamówień lub innych procesów."
            },
            {
                description: "Dane osobowe Użytkowników przechowywane są wyłącznie przez okres niezbędny do realizacji celów, w jakich zostały zebrane, zgodnie z obowiązującymi przepisami prawa. Po zakończeniu przetwarzania danych, są one usuwane lub zanonimizowane."
            }
        ]
    },
    {
        title: "Informacja o plikach cookies",
        points: [
            {
                description: "Serwis korzysta z plików cookies. Pliki cookies (tzw. „ciasteczka”) to dane informatyczne, głównie pliki tekstowe, które są przechowywane na urządzeniu końcowym Użytkownika Serwisu. Mają one na celu umożliwienie korzystania z funkcji Serwisu. Pliki cookies zawierają zazwyczaj nazwę strony internetowej, z której pochodzą, czas przechowywania na urządzeniu końcowym oraz unikalny numer."
            },
            {
                description: "Podstawą prawną przetwarzania danych osobowych z wykorzystaniem plików cookies jest uzasadniony interes administratora (art. 6 ust. 1 lit. (f) RODO), który polega na bieżącym monitorowaniu sposobu korzystania z Serwisu przez Użytkowników, analizie ich zainteresowań produktami oraz wyświetlaniu spersonalizowanych treści na stronie internetowej.",
            },
            {
                description: "Podmiotem odpowiedzialnym za umieszczanie plików cookies na urządzeniu końcowym Użytkownika oraz uzyskującym do nich dostęp jest Operator Serwisu oraz partnerzy techniczni współpracujący z Operatorem, o których mowa w punkcie 2 ust. 6 powyżej."
            },
            {
                description: "Pliki cookies mogą być wykorzystywane w następujących celach:",
                subs: [
                    "utrzymanie sesji Użytkownika Serwisu, m.in. do przechowywania adresu dostawy i zawartości koszyka;",
                    "utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki czemu Użytkownik nie musi ponownie wpisywać loginu i hasła na każdej podstronie;",
                    "tworzenie statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają z naszej strony internetowej, co umożliwia jej ulepszanie;",
                    "określenie profilu Użytkownika w celu wyświetlania mu dopasowanych reklam w sieciach reklamowych, szczególnie w sieci Google."
                ]
            },
            {
                description: "Rodzaje plików cookies:",
                subs: [
                    "Cookies sesyjne: Pliki tymczasowe przechowywane w urządzeniu końcowym Użytkownika do momentu wylogowania, opuszczenia strony lub zamknięcia przeglądarki;",
                    "Cookies stałe: Przechowywane w urządzeniu końcowym Użytkownika przez czas określony w parametrach pliku cookies lub do momentu ich ręcznego usunięcia przez Użytkownika."
                ]
            },
            {
                description: "Przeglądarki internetowe zazwyczaj domyślnie dopuszczają przechowywanie plików cookies. Użytkownicy mogą zmienić ustawienia przeglądarki, aby usunąć lub automatycznie blokować pliki cookies. Szczegółowe informacje są dostępne w dokumentacji przeglądarki."
            },
            {
                description: "Pliki cookies mogą zbierać następujące informacje:",
                subs: [
                    "unikalny identyfikator użytkownika,",
                    "adres IP użytkownika,",
                    "dane na temat aktywności na stronie (np. odwiedzone strony, czas spędzony na stronie),",
                    "informacje o urządzeniu i przeglądarce (np. typ przeglądarki, system operacyjny)."
                ]
            },
            {
                description: "Dane są zbierane w celach statystycznych oraz marketingowych, a także dla zapewnienia poprawnej obsługi zamówień i funkcjonowania Serwisu."
            },
            {
                description: "Skutki wyłączenia plików cookies: Wyłączenie lub ograniczenie plików cookies może wpłynąć na niektóre funkcje Serwisu, co może ograniczyć pełną funkcjonalność strony internetowej."
            },
            {
                description: "Cookies zewnętrzne: Pliki cookies mogą być wykorzystywane przez reklamodawców i partnerów współpracujących z Operatorem Serwisu. Zachęcamy do zapoznania się z polityką prywatności Google Analytics oraz innych firm, aby dowiedzieć się więcej na temat zasad korzystania z plików cookies."
            },
            {
                description: "Reklamy Google: Sieć reklamowa Google może wykorzystywać pliki cookies do wyświetlania reklam dopasowanych do preferencji Użytkownika. Użytkownik może zarządzać swoimi preferencjami dotyczącymi reklam za pomocą narzędzia Google: https://www.google.com/ads/preferences"
            }
        ]
    },
    {
        title: "Udostępnienie danych",
        points: [
            {
                description: "Dane osobowe użytkowników Serwisu mogą być udostępniane podmiotom zewnętrznym wyłącznie w granicach prawnie dozwolonych."
            },
            {
                description: "Dane umożliwiające identyfikację osoby fizycznej są udostępniane wyłącznie za zgodą tej osoby, chyba że przekazanie tych danych jest konieczne w celu realizacji zamówienia lub spełnienia wymogów prawnych.",
            },
            {
                description: "Operator Serwisu może być zobowiązany do udzielenia informacji zebranych przez Serwis upoważnionym organom, na podstawie zgodnych z prawem żądań, w zakresie wynikającym z obowiązujących przepisów prawa."
            },
            {
                description: "Dane osobowe Użytkowników mogą być przekazywane do podmiotów znajdujących się poza Europejskim Obszarem Gospodarczym, w tym do dostawcy usług hostingowych Amazon Web Services (AWS). AWS zapewnia odpowiedni poziom ochrony danych zgodnie z wymogami RODO, w oparciu o zatwierdzone mechanizmy przekazywania danych, takie jak standardowe klauzule umowne."
            }
        ]
    },
    {
        title: "Zarządzanie plikami cookies",
        points: [
            {
                description: "Każdy Użytkownik ma prawo dostępu do swoich danych osobowych oraz do ich poprawiania, usunięcia, ograniczenia przetwarzania lub przenoszenia. W celu skorzystania z tych praw Użytkownik może skontaktować się z Administratorem danych osobowych za pomocą danych kontaktowych podanych w niniejszej polityce."
            },
            {
                description: "Na stronie captain-potato.com korzystamy z narzędzia Cookiebot w celu zarządzania zgodami na pliki cookies. Użytkownik może zmieniać swoje preferencje dotyczące plików cookies za pomocą przycisku dostępnego w dolnym lewym rogu strony."
            },
            {
                description: "Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia swojej przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów: uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może uniemożliwić korzystanie z serwisu captain-potato.com.",
            },
            {
                description: "Informacje dotyczące ustawień przeglądarek internetowych dostępne są w jej menu (Pomoc) lub na stronie producenta."
            }
        ]
    },
    {
        title: "Bezpieczeństwo danych",
        desc: "Dokładamy wszelkich starań, aby chronić dane osobowe Użytkowników przed nieuprawnionym dostępem, utratą, zniszczeniem czy modyfikacją. W tym celu stosujemy odpowiednie środki techniczne i organizacyjne, w tym zabezpieczenia transmisji danych za pomocą szyfrowania SSL oraz monitorowanie dostępu do naszych systemów. Dodatkowo, korzystamy z usług zaufanych partnerów technologicznych, zapewniających wysoki poziom ochrony danych, takich jak Amazon Web Services (AWS), które spełniają międzynarodowe standardy bezpieczeństwa danych.",
        points: []
    }
]