import { Typography } from "components/Typography/Typography";
import { BasePage } from "pages/BasePage/BasePage";
import classes from "./PaymentCompleted.module.scss";

export const PaymentCompleted = () => {
  return (
    <BasePage>
      <div className={classes.Page}>
          <Typography format="t">Dziękujemy za zamówienie!</Typography>
          <Typography format="h1">
            W ciągu kilku minut wyślemy do Państwa maila z potwierdzeniem, jeśli płatność zakończy się pomyślnie.
          </Typography>
          <Typography paragraphClassname={classes.Page__Paragraph}><a href="/home" className={classes.Page__Link}>Wróć na główną</a></Typography>
      </div>
    </BasePage>
  );
};
