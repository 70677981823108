import { Typography } from "components/Typography/Typography"
import { Points } from "./Points"
import { Point } from "legal/types"

interface ParagraphsProps {
    textData: Point[]
    indexStart: number
}

export const Paragraphs = ({ textData, indexStart = 1 }: ParagraphsProps) => {
    return (
        <div>
            {textData.map((point, index) => {
                return (
                    <span>
                        <Typography format="h1" bold>{index+indexStart}. {point.title}</Typography>
                        <Typography format="h3">{point.desc}</Typography>
                        <Points point={point.points}/>
                    </span>
                )
            })}
        </div>
    )
}