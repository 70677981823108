import { BaseSection } from "sections/BaseSection/BaseSection"
import classes from './Vibe.module.scss'
import { Typography } from "components/Typography/Typography"

export const Vibe = () => {
    return (
        <BaseSection routeId="vibe">
            <div className={classes.Container}>
                <Typography format="t" testID="vibe-title">
                    Vibe
                </Typography>
                <div className={classes.Vibe} data-testid="vibe-photos">
                    <span className={classes.One}></span>
                    <span className={classes.Two}></span>
                    <span className={classes.Three}></span>
                    <span className={classes.Four}></span>
                    <span className={classes.BigSquare}></span>
                </div>
            </div>
        </BaseSection>
    )
} 