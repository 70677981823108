import classes from './PhotoCollage.module.scss'

export const PhotoCollage = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.PhotoCollage} data-testid='photo-collage'>
                <span className={classes.One}></span>
                <span className={classes.Two}></span>
                <span className={classes.Three}></span>
                <span className={classes.Four}></span>
            </div>
        </div>
    )
}