import { ReactNode, useEffect, useState } from "react";
import { OrderContext } from "./OrderContext";
import { ItemInBasket, OrderFormat } from "types/orderFormat";
import * as OrderFunctions from "./orderContextFunctions";

export const OrderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [order, setOrder] = useState<OrderFormat | undefined>(undefined);

  useEffect(() => {
    const savedOrder = localStorage.getItem("order");
    if (savedOrder) {
      setOrder(JSON.parse(savedOrder));
    }
  }, []);

  useEffect(() => {
    if (order) {
      localStorage.setItem("order", JSON.stringify(order));
    }
  }, [order]);
 
  useEffect(() => {
    if(order && order.items) {
      const totalPrice = order.items.reduce((sum, item) => sum + item.price, 0)
      setFullPrice(totalPrice)
    }
  }, [order?.items])

  const setExistingOrder = (order: OrderFormat) => {
    setOrder(order);
  };

  const addItem = (item: ItemInBasket) => {
    setOrder((prevOrder) => OrderFunctions.addItem(prevOrder, item));
  };

  const removeItem = (item: ItemInBasket) => {
    setOrder((prevOrder) => OrderFunctions.removeItem(prevOrder, item));
  };

  const setFullPrice = (price: number) => {
    setOrder((prevOrder) => {
      if (!prevOrder) return prevOrder; 
      return { ...prevOrder, fullPrice: price }; 
    });
  };
  



  return (
    <OrderContext.Provider
      value={{
        order,
        setExistingOrder,
        addItem,
        removeItem
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
