import { Typography } from "components/Typography/Typography"

import classes from '../Terms.module.scss'
import classNames from "classnames"
import { SinglePoint } from "legal/types"

interface PointsProps {
    point: SinglePoint[]
}

export const Points = ({point}: PointsProps) => {
    const convertNumbersToLetters = (num: number): string => {
        return String.fromCharCode(97 + num)
    }

    return (
        <div>
               {point.map((sub, index) => {
                    return (
                        <>
                            <Typography format="h3" paragraphClassname={classNames(classes.Paragraph, classes.PointsOnly)}>{index + 1}. {sub.description}</Typography>         
                            {sub.subs?.map((subpoint, index) => {
                                return (
                                    <Typography format="h3" paragraphClassname={classNames(classes.Paragraph, classes.Subpoints)}>{convertNumbersToLetters(index)}) {subpoint}</Typography>
                                )
                            })}
                        </>
                    )   
                })}
        </div>
    )
}