import * as Yup from "yup";

const containsJsKeyword = (value?: string) => {
  if (!value) {
    return false;
  }
  const jsKeywords = [
    "break",
    "case",
    "catch",
    "class",
    "const",
    "continue",
    "debugger",
    "default",
    "delete",
    "double",
    "else",
    "enum",
    "export",
    "extends",
    "false",
    "finally",
    "for",
    "function",
    "if",
    "implements",
    "import",
    "instanceof",
    "interface",
    "let",
    "new",
    "null",
    "return",
    "super",
    "switch",
    "this",
    "throw",
    "try",
    "true",
    "typeof",
    "var",
    "void",
    "while",
    "with",
    "yield",
  ];
  return jsKeywords.some((keyword) => value.includes(keyword));
};

export const pickupValidationSchema = Yup.object().shape({
  type: Yup.string().required(),
  addCutlery: Yup.boolean(),
  tcsCheck: Yup.boolean()
    .required(
      "Aby złożyć zamówienie, prosimy o zapoznanie się z regulaminem i jego akceptację"
    )
    .oneOf(
      [true],
      "Aby złożyć zamówienie, prosimy o zapoznanie się z regulaminem i jego akceptację"
    ),
  comment: Yup.string().test(
    "no-js-keywords",
    "Input contains reserved JavaScript keywords.",
    (value) => !containsJsKeyword(value)
  ),
  firstName: Yup.string()
    .required("Imię jest wymagane")
    .test(
      "no-js-keywords",
      "Input contains reserved JavaScript keywords.",
      (value) => !containsJsKeyword(value)
    )
    .max(30, "Osiągnięto limit znaków"),
  lastName: Yup.string()
    .required("Nazwisko jest wymagane")
    .test(
      "no-js-keywords",
      "Input contains reserved JavaScript keywords.",
      (value) => !containsJsKeyword(value)
    )
    .max(30, "Osiągnięto limit znaków"),
  phoneNumber: Yup.string()
    .matches(/^[+0-9]*$/, "Nie poprawny numer telefonu")
    .max(13, "Nie poprawny numer telefonu")
    .required("Numer telefonu jest wymagany"),
  email: Yup.string()
    .email("Nie poprawny email")
    .required("Email jest wymagany"),
});

export const deliveryValidationSchema = Yup.object().shape({
  type: Yup.string().required(),
  addCutlery: Yup.boolean(),
  tcsCheck: Yup.boolean()
  .required(
    "Aby złożyć zamówienie, prosimy o zapoznanie się z regulaminem i jego akceptację"
  )
  .oneOf(
    [true],
    "Aby złożyć zamówienie, prosimy o zapoznanie się z regulaminem i jego akceptację"
  ),
  comment: Yup.string().test(
    "no-js-keywords",
    "Input contains reserved JavaScript keywords.",
    (value) => !containsJsKeyword(value)
  ),
  firstName: Yup.string()
    .test(
      "no-js-keywords",
      "Input contains reserved JavaScript keywords.",
      (value) => !containsJsKeyword(value)
    )
    .max(30, "Osiągnięto limit znaków")
    .required("Imię jest wymagane"),
  lastName: Yup.string()
    .required("Nazwisko jest wymagane")
    .test(
      "no-js-keywords",
      "Input contains reserved JavaScript keywords.",
      (value) => !containsJsKeyword(value)
    )
    .max(30, "Osiągnięto limit znaków"),
  phoneNumber: Yup.string()
    .matches(/^[+0-9]*$/, "Nie poprawny numer telefonu")
    .max(13, "Nie poprawny numer telefonu")
    .required("Numer telefonu jest wymagany"),
  email: Yup.string()
    .email("Nie poprawny email")
    .required("Email jest wymagany"),
  address: Yup.string()
    .nullable()
    .required("Proszę uzupełnić powyższe pole")
});
