import { Typography } from "components/Typography/Typography"
import { IoCartOutline } from "react-icons/io5";
import classes from './OpenItemModalButton.module.scss'
import { useEffect, useState } from "react";
import { AddItemModal } from "components/Modals/AddItemModal/AddItemModal";
import { useItemContext } from "context/ItemContext";
import { BasketButton } from "lib/BasketButton/BasketButton";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useOpeningTimesAction } from "effects/useOpeningTimesActions";

interface AddToBasketButtonProps {
    price: number
    itemCategory: string
    itemTitle: string
    testId?: string
}

export const OpenItemModalButton = ({ itemCategory, itemTitle, price, testId}: AddToBasketButtonProps) => {
    const [open, setOpen] = useState(false)
    const { updateItem } = useItemContext()
    const { width } = useWindowDimensions();
    const { checkIsOpen } = useOpeningTimesAction()

    useEffect(() => {
        if (open) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "scroll";
        }
      }, [open]);

    const openModal = async () => {
        await checkIsOpen({
            onSuccess: () => setOpen(true)
          })
    }

    const closeModal = () => {
        setOpen(false)
        updateItem()
    }

    return (
        <>
        <div className={classes.AddToBasketButton} onClick={openModal} data-testid={testId}>
            <IoCartOutline />
            {width > 830 && <Typography format="h3">Od {price.toFixed(2)} zł</Typography>}
        </div>
        {open && <AddItemModal itemCategory={itemCategory} itemTitle={itemTitle} onClose={closeModal}/>}
        <BasketButton />
        </>
    )
}