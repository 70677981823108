import { ComponentType } from "react"
import classes from './CircledIcon.module.scss'
import classNames from "classnames"

interface CircledIconProps {
    Icon: ComponentType
    classname?: string
}

export const CircledIcon = ({ Icon, classname }: CircledIconProps) => {
    return (
        <div className={classNames(classes.CircledIcon, classname)}>
            <Icon/>
        </div>
    )
}