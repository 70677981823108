import { Typography } from "components/Typography/Typography";
import { BasePage } from "pages/BasePage/BasePage";
import { privacyPolicyData } from "legal/privacyPolicyData";
import { Paragraphs } from "legal/Terms/Points/Paragraphs";

export const PrivacyPolicy = () => {
  return (
    <BasePage>
      <div>
        <Typography format="h1" bold>
          Polityka prywatności
        </Typography>
        <Typography>
          Niniejsza polityka prywatności zawiera informacje o przetwarzaniu
          danych osobowych zgodnie z art. 13 ust. 1 i ust. 2 RODO.{" "}
        </Typography>
      </div>
      <Paragraphs textData={privacyPolicyData} indexStart={1} />
    </BasePage>
  );
};
