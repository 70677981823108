import { DayOfTheWeek } from "./types";

export const openingTimes: DayOfTheWeek[] = [
    {
        day: 0,
        name: "Niedziela",
        times: {
            start: "13:00",
            end: "21:00"
        }
    },
    {
        day: 1,
        name: "Poniedziałek",
        isClosed: true,
        times: {
            start: "13:00",
            end: "22:30"
        }
    },
    {
        day: 2,
        name: "Wtorek",
        isClosed: true,
        times: {
            start: "13:00",
            end: "22:30"
        }
    },
    {
        day: 3,
        name: "Środa",
        isClosed: true,
        times: {
            start: "13:00",
            end: "21:00"
        }
    },
    {
        day: 4,
        name: "Czwartek",
        times: {
            start: "13:00",
            end: "21:00"
        }
    },
    {
        day: 5,
        name: "Piątek",
        times: {
            start: "13:00",
            end: "21:00"
        }
    },
    {
        day: 6,
        name: "Sobota",
        times: {
            start: "13:00",
            end: "21:00"
        }
    }
]