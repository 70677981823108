import { IoCloseCircleSharp } from "react-icons/io5";
import classes from "./CloseButton.module.scss";
import classNames from "classnames";

interface CloseButtonProps {
  onClose: () => void;
  className?: string;
  testID?: string
}

export const CloseButton = ({ onClose, className, testID }: CloseButtonProps) => {
  return (
    <IoCloseCircleSharp onClick={onClose} className={classNames(classes.CloseButton, className)} data-testid={testID}/>
  );
};
