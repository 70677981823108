import axios from "axios"
import { ENV, ORDER_API, PARAM } from "config"
import { CreateOrderRequest, CreateOrderResponse } from "./types"

export const useCreateOrder = () => {

    const createOrder = async (orderRequest: CreateOrderRequest) => {
        const dev = ENV === 'development' ? 'development' : 'pr'
        try {
            const response = await axios.post<CreateOrderResponse>(
                `${ORDER_API}/${PARAM}/createOrder`,
                {
                    request: orderRequest,
                    env: ''
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            return response.data
        } catch(e) {
            console.error(e)
            return undefined
        }
    }

    return { createOrder }
}