import React, { forwardRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Typography } from 'components/Typography/Typography'; // Update the import path as necessary
import classes from './InputField.module.scss'; // Update the import path as necessary

interface InputFieldProps {
  nameID: string;
  title: string;
  testId?: string;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ nameID, title, testId }, ref) => {
    
    return (
      <div className={classes.InputField} data-testid={testId}>
        <Field 
          name={nameID} 
          placeholder={title} 
          as="input" 
          type="text" 
          innerRef={ref} 
        />
        <ErrorMessage name={nameID}>
          {(msg) => (
            <Typography
              format="b"
              paragraphClassname={classes.InputField__ErrorMessage}
            >
              {msg}
            </Typography>
          )}
        </ErrorMessage>
      </div>
    );
  }
);

InputField.displayName = 'InputField'; // Set a display name for the component
