import { Typography } from "components/Typography/Typography"
import { MenuButton } from "lib/MenuButton/MenuButton"
import classes from './AboutUsText.module.scss'
import classNames from "classnames"

export const AboutUsText = () => {
    return (
        <div className={classes.AboutUsText}>
            <Typography format="t" testID="aboutUs-title">
                O Nas
            </Typography>
            <Typography format="h2" paragraphClassname={classNames(classes.MaxWidth, classes.Paragraph)} testID="aboutUs-first-paragraph">
            Nasz food truck serwuje pyszne ziemniaki z dodatkami oraz nachosy, które można skomponować według własnego gustu lub wybrać z menu. Oferujemy wysokiej jakości mięsa, fasolki, sosy oraz opcje wegańskie. W menu mamy również desery i napoje.
            </Typography>
            <Typography format="h2" paragraphClassname={classNames(classes.MaxWidth, classes.Paragraph)} testID="aboutUs-second-paragraph">
            Można zjeść na miejscu, zadzwonić i zamówić na odbiór lub dostawę, albo zamówić online. Gwarantujemy świetną atmosferę i pozytywny klimat.
            </Typography>

            <MenuButton classname={classes.AboutUsText__Button} testId="aboutUs-menu-button"/>
        </div>
    )
}