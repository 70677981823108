import { useState, useEffect } from "react";

export const useHandleScroll = () => {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY === 0) {
          setIsScrolling(false);
        } else {
          setIsScrolling(true);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return { isScrolling }
}