import { Typography } from "components/Typography/Typography";
import classes from './Terms.module.scss'
import { SellerData } from "./Points/SellerData";
import { Definitions } from "./Points/Definitions";
import { Paragraphs } from "./Points/Paragraphs";
import { terms } from "legal/termsData";
import { BasePage } from "pages/BasePage/BasePage";

export const Terms = () => {
  return (
    <BasePage>
    <div className={classes.Title}>
    <Typography format="h1" bold>Regulamin Sklepu Internetowego Captain Potato</Typography>
    <Typography>(obowiązuje od 23.09.2024 r.)</Typography>
    </div>
      <SellerData/>
      <Definitions/>
      <Paragraphs textData={terms} indexStart={3}/>
   </BasePage>
  );
};
