import { useField } from "formik";
import { IoMdCheckbox } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import classes from './Checkbox.module.scss'
import { useEffect } from "react";

export const TCsCheckbox = () => {
    const [field, , helpers] = useField('tcsCheck');
    const { setValue } = helpers;

    useEffect(() => {
        setValue(false);
    }, [])

    const onCheck = () => {
        setValue(true);
    };

    const onUncheck = () => {
        setValue(false);
    };

    return (
        <div className={classes.Checkbox} data-testid='tcs-checkbox'>
            {field.value ? (
                <IoMdCheckbox onClick={onUncheck} />
            ) : (
                <MdCheckBoxOutlineBlank onClick={onCheck} />
            )}
        </div>
    );
}