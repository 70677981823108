import { ReactElement, useState } from "react"
import { Typography } from "components/Typography/Typography"
import classes from 'components/MenuTabs/Tabs/Tabs.module.scss'
import { MenuListPanelProps } from "components/MenuTabs/MenuListPanel/MenuListPanel"
import classNames from "classnames"

interface MobileTabsProps {
    children: ReactElement<MenuListPanelProps>[]
}

export const MobileTabs = ({ children }: MobileTabsProps) => {
    const [selected, setSelected] = useState<number>(0)
    
    return (
        <div className={classNames(classes.Tabs)}>
          <ul className={classNames(classes.TabsList, classes.MobileTabs)}>
            {children.map((panel, index) => {
              const isSelected = selected === index;
              const typeKey = `type-${index}`
              return (
                <>
                  <li
                    key={index}
                    onClick={() => setSelected(index)}
                    className={isSelected ? classes.Selected : ""}
                  >
                    <Typography format="h1" bold key={typeKey}>
                      {panel.props.title.toUpperCase()}
                    </Typography>
                  </li>
                  {isSelected && <div className="tab">{children[selected]}</div>}
                </>
              );
            })}
          </ul>
        </div>
      )
}