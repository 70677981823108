import { useState } from "react"
import { IoMdCheckbox } from "react-icons/io"
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import classes from './Checkbox.module.scss'

interface CheckboxProps {
    onChecked: () => void
    onUnchecked: () => void
    testID?: string
}

export const Checkbox = ({ onChecked, onUnchecked, testID }: CheckboxProps) => {
    const [isChecked, setChecked] = useState(false)

    const onCheck = () => {
        setChecked(true)
        onChecked()
    }

    const onUncheck = () => {
        setChecked(false)
        onUnchecked()
    }

    return (
        <div className={classes.Checkbox} data-testid={testID}>
            {isChecked ? (
                <IoMdCheckbox onClick={onUncheck}/>
            ) : (
                <MdCheckBoxOutlineBlank onClick={onCheck}/>
            )}
        </div>
    )
}