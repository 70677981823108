import { Typography } from "components/Typography/Typography";
import { RadioOptions } from "data/menuListData";
import { useCallback, useEffect, useState } from "react";
import classes from './Radios.module.scss'
import { RadioList } from "./RadioList/RadioList";
import { useFormikContext } from "formik";
import { ItemInBasket } from "types/orderFormat";

interface OptionEntry {
  sectionTitle: string;
  option: string;
  price: number;
}

interface RadiosProps {
  items: RadioOptions[]
}

const getFirstOptionEntries = (items: RadioOptions[]): OptionEntry[] => {
  return items.map(({ title, options }) => {
    const [firstKey, firstValue] = Array.from(options.entries())[0] || ["", 0];
    
    return {
      sectionTitle: title,
      option: firstKey,
      price: firstValue
    };
  });
};

export const Radios = ({ items }: RadiosProps) => {
  const [chosenOptions, setChosenOptions] = useState<OptionEntry[]>(getFirstOptionEntries(items));
  const { setFieldValue } = useFormikContext<ItemInBasket>()

  const updateSetOptions = useCallback((sectionTitle: string, option: string, price: number) => {
    setChosenOptions(prevOptions => {
      const existingOptionIndex = prevOptions.findIndex(entry => entry.sectionTitle === sectionTitle);

      let updatedOptions: OptionEntry[];

      if (existingOptionIndex > -1) {
        updatedOptions = [...prevOptions];
        updatedOptions[existingOptionIndex] = { sectionTitle, option, price };
      } else {
        updatedOptions = [...prevOptions, { sectionTitle, option, price }];
      }

      return updatedOptions;
    });
  }, []);

  useEffect(() => {
    const optionNamesList = chosenOptions.map(entry => entry.option);
    const totalSum = chosenOptions.reduce((acc, entry) => acc + entry.price, 0);
 
    setFieldValue('radios', optionNamesList);
    setFieldValue('cost.radios', totalSum);
  }, [chosenOptions, setFieldValue]);

  return ( 
    <div className={classes.Radios} data-testid='radios-list'>
      {items.map((selection, index) => {
        const titleTestId = `radios-section-${index}-title`
        const radioSectionTestId = `radios-section-${index}-list`
        return (
          <div key={selection.title}>
            <Typography testID={titleTestId}>{selection.title}</Typography>
            <RadioList items={selection.options} setCheckedOption={(optionName, price) => updateSetOptions(selection.title, optionName, price)} testID={radioSectionTestId}/>
          </div>
        );
      })}
    </div>
  );
};
