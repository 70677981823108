import { BaseSection } from 'sections/BaseSection/BaseSection'
import classes from './ContactSection.module.scss'
import { Typography } from "components/Typography/Typography"
import { Map } from './Map/Map'
import { OurInfo } from './OurInfo/OurInfo'

export const ContactSection = () => {
    return (
        <BaseSection routeId='contact'>
            <div className={classes.ContactSection}>
                <Typography format="t" testID='contact-title'>
                    Kontakt
                </Typography>
                <Map/>
                <OurInfo/>
            </div>
        </BaseSection>
    )
} 