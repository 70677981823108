import { OrderFormat, ItemInBasket } from 'types/orderFormat';

export const addItem = (
  prevOrder: OrderFormat | undefined,
  item: ItemInBasket
): OrderFormat => {
  if (!prevOrder) return { items: [item], fullPrice: 0 };
  return { ...prevOrder, items: [...prevOrder.items, item] };
};

export const removeItem = (
  prevOrder: OrderFormat | undefined,
  item: ItemInBasket
): OrderFormat | undefined => {
  if (!prevOrder) return undefined;
  return {
    ...prevOrder,
    items: prevOrder.items.filter((i) => i.id !== item.id),
  };
};
