import { Typography } from "components/Typography/Typography";
import classes from "./DeliverSelection.module.scss";
import AutocompleteAddress from "components/AutocompleteAddress/AutocompleteAddress";
import { Field, ErrorMessage } from "formik";

export const DeliverSelection = () => {
  return (
    <div className={classes.DeliverSelection} data-testid="delivery-content">
      <Typography format="b" bold testID="delivery-address-input-label">
        Adres
      </Typography>
      <div className={classes.DeliverSelection__InputGroup}>
        <Field name="street" component={AutocompleteAddress} />
        <ErrorMessage name="street">
          {(msg) => (
            <Typography
              format="b"
              paragraphClassname={classes.BasketModal__ErrorMessage}
            >
              {msg}
            </Typography>
          )}
        </ErrorMessage>
      </div>
      <div className={classes.DeliverSelection__InputGroup}>
        <Typography
          format="b"
          bold
          testID="delivery-info"
        >
          Zamówienia na dostawę przyjmujemy do 6 km. <br/>
          Minimalne zamówienie to 40 zł. Dostawa jest darmowa.
        </Typography>
      </div>
    </div>
  );
};
