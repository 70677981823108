import { useState } from "react";
import { HiMiniSpeakerXMark } from "react-icons/hi2";
import classes from "./NotFound.module.scss";

export const Player = () => {
  const [playing, setPlaying] = useState(false);
  return (
    <div>
      {playing ? (
               <iframe
               title="yt-vid"
               width="400px" 
               height="300px"
               className="cookieconsent-optin-preferences"
               src="https://www.youtube.com/embed/YCCDQfYMo0s?autoplay=1&controls=1&start=19"
               frameBorder="0"
             ></iframe>
      ) : (
        <HiMiniSpeakerXMark className={classes.Icon} onClick={() => setPlaying(true)}/>
      )}
    </div>
  );
};
