import { Typography } from "components/Typography/Typography"
import classes from './AddToOrderButton.module.scss'

interface AddToOrderButtonProps {
    price: number
}

export const AddToOrderButton = ({price}: AddToOrderButtonProps) => {
    return (
        <button className={classes.AddToOrderButton} type="submit" data-testid='menu-item-submit-button'>
            <Typography format="b" bold>
                Dodaj do koszyka 
            </Typography>
            <Typography format="b" bold paragraphClassname={classes.AddToOrderButton__Price}>
                {price.toFixed(2)} zł
            </Typography>
        </button>
    )
}