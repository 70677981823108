import { Logo } from 'components/Logo/Logo'
import { Typography } from 'components/Typography/Typography'
import { OrderOnlineButton } from 'lib/OrderOnlineButton/OrderOnlineButton'
import classes from './TopBar.module.scss'
import { useHandleScroll } from 'hooks/useHandleScroll'
import classNames from 'classnames'
import { Link } from 'components/Link/Link'

export const TopBar = () => {
		const { isScrolling } = useHandleScroll()

    return (
			<div className={classNames(
				classes.TopBar,
				isScrolling && classes.TopBar__ScrolledDown
			)}>
        <div>
          <Logo/>
        </div>
				<div className={classes.TopBar__MenuActions}>
					<Typography format="h3" bold testID='vibe-button'>
						<Link anchor='vibe'>GALERIA</Link>
					</Typography>
					<Typography format="h3" bold testID='contact-button'>
						<Link anchor='contact'>KONTAKT</Link>
					</Typography>
					<OrderOnlineButton/>
				</div>
			</div>
    )
}