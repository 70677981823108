import { Typography } from "components/Typography/Typography"
import classes from './SubmitOrderButton.module.scss'
import { useOrderContext } from "context/OrderContext"

interface SubmitOrderButtonProps {
    onSubmit?: () => void
}

export const SubmitOrderButton = ({ onSubmit }: SubmitOrderButtonProps) => {
    const { order } = useOrderContext()

    return (
        <button className={classes.SubmitOrderButton} onClick={onSubmit} type="submit" data-testid="basket-submit-order-button">
            <Typography format="b" bold>
                Zamów i przejdź do płatności
            </Typography>
            <Typography format="b" bold paragraphClassname={classes.SubmitOrderButton__Price}>
                {order?.fullPrice.toFixed(2)} zł
            </Typography>
        </button>
    )
}