import App from 'App';
import { PrivacyPolicy } from 'legal/PrivacyPolicy/PrivacyPolicy';
import { Terms } from 'legal/Terms';
import { NotFound } from 'pages/NotFound';
import { PaymentCompleted } from 'pages/PaymentCompleted';
import { Routes, Route } from 'react-router-dom';

export const RoutingLayout = () => {
    return (
        <Routes>
            <Route path='/' element={<App />}/>
            <Route path='/home' element={<App />}/>
            <Route path='/payment-completed' element={<PaymentCompleted/>}/>
            <Route path='/terms-and-conditions' element={<Terms/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            <Route path='/sitemap' element={<PrivacyPolicy/>}/>
            <Route path='*' element={<NotFound/>} />
       </Routes>
    )
}