import { useEffect, useState } from "react";
import classNames from "classnames";
import { Typography } from "components/Typography/Typography";
import classes from "./BasketButton.module.scss";
import { useOrderContext } from "context/OrderContext";
import { IoCartOutline } from "react-icons/io5";
import { BasketModal } from "components/Modals/BasketModal/BasketModal";
import { LoadingOverlay } from "sections/LoadingOverlay/LoadingOverlay";
import { useOpeningTimesAction } from "effects/useOpeningTimesActions";

export const BasketButton = () => {
  const { order } = useOrderContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { checkIsOpen } = useOpeningTimesAction()

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [open]);
  
  if (!order || order.items.length < 1) {
    return null;
  }

  const openModal = async () => {
    await checkIsOpen({
      onError: () => setLoading(false),
      onSuccess: () => setOpen(true)
    })
  };

  return (
    <>
      <div
        className={classNames(classes.BasketButton)}
        onClick={openModal}
        // data-testid="basket-button"
      >
        <IoCartOutline style={{ fontSize: "22px" }} />
        <Typography format="h3" bold testID="basket-button">
          KOSZYK ({order.items.length})
        </Typography>
      </div>
      {open && (
        <BasketModal onClose={() => setOpen(false)} setLoading={setLoading} />
      )}
      {loading && <LoadingOverlay />}
    </>
  );
};
