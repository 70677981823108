import { Typography } from "components/Typography/Typography"
import { ComponentType, ReactNode } from "react"
import classes from './InfoCard.module.scss'
import { CircledIcon } from "./CircledIcon/CircledIcon"

interface InfoCardProps {
    title: string
    desc: ReactNode
    Icon: ComponentType
    testID: string
}

export const InfoCard = ({
    title,
    desc,
    Icon,
    testID
}: InfoCardProps) => {
    return (
        <div className={classes.InfoCard} data-testid={testID}>
            <CircledIcon Icon={Icon} classname={classes.InfoCard__Icon}/>
            <Typography format="h1" bold>{title}</Typography>
            {desc}
        </div>
    )
}