import React from "react"

export interface MenuListPanelProps {
    title: string
    children: React.ReactNode
}

export const MenuListPanel = ({ title, children }: MenuListPanelProps) => {
    return (
        <div>{children}</div>
    )
}