import { DeliveryTypeForm, OrderFormValues } from "components/Modals/BasketModal/types"
import { Product, CreateOrderRequest } from "hooks/payu/types"
import moment from "moment"
import { SingleItem } from "types/emailRequestFormat"
import { ItemInBasket, OrderFormat } from "types/orderFormat"

const getFullPrice = (price: number) => {
    const converted = price*100
    return converted.toString()
}

const getProducts = (items: ItemInBasket[]) => {
    const products: Product[] = items.map(item => {
        return {
            name: item.name,
            unitPrice: getFullPrice(item.price),
            quantity: item.quanity.toString()
        }
    })
    return products
}

const flattendExtras = (addOns: string[], radios: string[]) => {
    const flattendAddOns = addOns.join(', ')
    const flattenedRadios = radios.join(', ')
    
    return [flattendAddOns, flattenedRadios].filter(Boolean).join(', ');
}

const parseItems = (items: ItemInBasket[]): SingleItem[] => {
    const newItems: SingleItem[] = items.map(item => {
        return {
            name: item.name,
            quantity: item.quanity,
            price: item.price,
            extras: flattendExtras(item.addOns, item.radios),
            comment: item.comment
        }
    })
    return newItems
}

const getAddress = (orderInformation: OrderFormValues) => {
    const street = (orderInformation as DeliveryTypeForm).address

    if(!street) {
        return 'Odbiór osobisty'
    } else {
        return street
    }
}

const generateOrderNumber = () => {
    return Math.floor(1000 + Math.random() * 9000).toString()
}

export const createOrderRequest = (values: OrderFormValues, ipAddress: string, order: OrderFormat) => {
    const dateTime = new Date()
    const formattedDate = (moment(dateTime)).format('HH:mm DD-MMM-YYYY')

    const newRequest: CreateOrderRequest = {
        details: {
            customerIp: ipAddress,
            totalAmount: getFullPrice(order.fullPrice),
            products: getProducts(order.items),
        },
        user: { //BOTH 
            email: values.email,
            phoneNumber: values.phoneNumber,
            firstName: values.firstName,
            lastName: values.lastName,
        },
        items: parseItems(order.items), // DB
        orderInfo: { //DB
            comment: values.comment ?? '',
            total: order.fullPrice,
            paymentForm: '',
            address: getAddress(values),
            orderDateTime: formattedDate,
            orderNo: generateOrderNumber(),
            addCutlery: values.addCutlery ? 'Tak' : 'Nie'
        }

    }
    return newRequest
} 